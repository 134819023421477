import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://70198af5ea1848f48ad88604a93ec766@o974819.ingest.sentry.io/6518020',
  tracesSampleRate: 1.0,
});
