export const bootIntercom = () => {
  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
  });
};

export const rebootIntercom = () => {
  window.Intercom('shutdown');
  bootIntercom();
};
