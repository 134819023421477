import { add } from 'date-fns';
import Cookies from 'js-cookie';

const authRedirect = '19d3842887211d5471fbc11c99bf5385'; // md5 auth_redirect

const get = () => Cookies.get(authRedirect);

const AuthRedirect = {
  set: (
    url: string,
    {
      ttl,
    }: {
      /** How long the redirect should live in seconds */
      ttl: number;
    },
  ) => {
    Cookies.set(authRedirect, url, {
      expires: add(new Date(), { seconds: ttl }),
    });
  },
  consume: () => {
    const redirect = get();
    Cookies.remove(authRedirect);
    return redirect;
  },
  hasRedirect: () => {
    return !!get();
  },
  purge: () => {
    Cookies.remove(authRedirect);
  },
};

export default AuthRedirect;
