import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import AccountLinkWelcome from './AccountLinkWelcome';

export enum ModalType {
  ACCOUNT_LINK_WELCOME = 'account-link-welcome',
  TRANSACTION_DETAILS = 'transaction-details',
}

const Modals = () => {
  const router = useRouter();

  const modalType = router.query.modal as ModalType;

  const onClose = useCallback(() => {
    router.replace({ query: { ...router.query, modal: null } }, router.asPath, {
      shallow: true,
    });
  }, [router]);

  switch (modalType) {
    case ModalType.ACCOUNT_LINK_WELCOME: {
      return <AccountLinkWelcome onClose={onClose} />;
    }
    default: {
      return null;
    }
  }
};

export default Modals;
