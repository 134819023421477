import { Auth0Provider } from '@auth0/auth0-react';
import { ConfigProvider } from 'antd';
import { AppProps } from 'next/app';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import AuthProvider from '../components/AuthProvider';
import IntercomProvider from '../components/IntercomProvider';
import Modals from '../components/Modals';
import store, { persistor } from '../lib/store';

import 'flag-icons/css/flag-icons.min.css';
import 'antd/dist/reset.css';
import './styles.css';

/**
 * The "homepage" for a logged in user
 */
export const appEntryPoint = '/transactions';

let scope: undefined | 'mfa:required' = 'mfa:required';

// Cypress will trip rate limiting if we login over and over with mfa
if (typeof window !== 'undefined' && (window as any)?.Cypress) {
  scope = undefined;
}

const App = ({ Component, pageProps }: AppProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#1890ff',
        },
      }}
    >
      <Auth0Provider
        audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE!}
        domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN!}
        clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
        cacheLocation="localstorage"
        scope={scope}
        useRefreshTokens
      >
        <AuthProvider persistor={persistor}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <IntercomProvider>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}

                <Component {...pageProps} />
                <Modals />
              </IntercomProvider>
            </PersistGate>
          </Provider>
        </AuthProvider>
      </Auth0Provider>
    </ConfigProvider>
  );
};

export default App;
