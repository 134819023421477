import { Card as AntCard } from 'antd';
import classNames from 'classnames';
import React, { ComponentProps } from 'react';

import styles from './card.module.css';

const Card = ({
  children,
  ...antCardProps
}: ComponentProps<typeof AntCard>) => (
  <AntCard
    {...antCardProps}
    className={classNames(styles.card, antCardProps.className)}
    bordered={false}
  >
    {children}
  </AntCard>
);

export default Card;
