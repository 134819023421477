import { LoadingOutlined } from '@ant-design/icons';
import { Typography, Spin, Space } from 'antd';
import React from 'react';

import Card from '../Card';
import styles from './styles.module.css';

const { Text } = Typography;

type FullscreenLoadingProps = {
  message?: string;
};

const FullscreenLoading = ({ message }: FullscreenLoadingProps) => {
  return (
    <Space className={styles.wrapper} align="center">
      <Card className={styles.card}>
        <Spin
          className={styles.spinner}
          indicator={<LoadingOutlined />}
          size="large"
        />

        <Text>{message}</Text>
      </Card>
    </Space>
  );
};

export default FullscreenLoading;
