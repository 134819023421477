/* eslint-disable */
import _m0 from 'protobufjs/minimal';
import { ClientQuote, Money } from '../fx/types';

export const protobufPackage = 'transactions';

/** buf:lint:ignore COMMENT_ENUM */
export enum FeeType {
  FEE_TYPE_INVALID = 0,
  FEE_TYPE_FIXED = 1,
  FEE_TYPE_PERCENTAGE = 2,
  UNRECOGNIZED = -1,
}

export function feeTypeFromJSON(object: any): FeeType {
  switch (object) {
    case 0:
    case 'FEE_TYPE_INVALID':
      return FeeType.FEE_TYPE_INVALID;
    case 1:
    case 'FEE_TYPE_FIXED':
      return FeeType.FEE_TYPE_FIXED;
    case 2:
    case 'FEE_TYPE_PERCENTAGE':
      return FeeType.FEE_TYPE_PERCENTAGE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FeeType.UNRECOGNIZED;
  }
}

export function feeTypeToJSON(object: FeeType): string {
  switch (object) {
    case FeeType.FEE_TYPE_INVALID:
      return 'FEE_TYPE_INVALID';
    case FeeType.FEE_TYPE_FIXED:
      return 'FEE_TYPE_FIXED';
    case FeeType.FEE_TYPE_PERCENTAGE:
      return 'FEE_TYPE_PERCENTAGE';
    case FeeType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum FeeKind {
  FEE_KIND_INVALID = 0,
  FEE_KIND_COLLECTION = 1,
  FEE_KIND_CONVERSION = 2,
  FEE_KIND_DISBURSEMENT = 3,
  FEE_KIND_SERVICE = 4,
  UNRECOGNIZED = -1,
}

export function feeKindFromJSON(object: any): FeeKind {
  switch (object) {
    case 0:
    case 'FEE_KIND_INVALID':
      return FeeKind.FEE_KIND_INVALID;
    case 1:
    case 'FEE_KIND_COLLECTION':
      return FeeKind.FEE_KIND_COLLECTION;
    case 2:
    case 'FEE_KIND_CONVERSION':
      return FeeKind.FEE_KIND_CONVERSION;
    case 3:
    case 'FEE_KIND_DISBURSEMENT':
      return FeeKind.FEE_KIND_DISBURSEMENT;
    case 4:
    case 'FEE_KIND_SERVICE':
      return FeeKind.FEE_KIND_SERVICE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FeeKind.UNRECOGNIZED;
  }
}

export function feeKindToJSON(object: FeeKind): string {
  switch (object) {
    case FeeKind.FEE_KIND_INVALID:
      return 'FEE_KIND_INVALID';
    case FeeKind.FEE_KIND_COLLECTION:
      return 'FEE_KIND_COLLECTION';
    case FeeKind.FEE_KIND_CONVERSION:
      return 'FEE_KIND_CONVERSION';
    case FeeKind.FEE_KIND_DISBURSEMENT:
      return 'FEE_KIND_DISBURSEMENT';
    case FeeKind.FEE_KIND_SERVICE:
      return 'FEE_KIND_SERVICE';
    case FeeKind.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum FeeTarget {
  FEE_TARGET_INVALID = 0,
  FEE_TARGET_SOURCE_AMOUNT = 1,
  FEE_TARGET_DESTINATION_AMOUNT = 2,
  UNRECOGNIZED = -1,
}

export function feeTargetFromJSON(object: any): FeeTarget {
  switch (object) {
    case 0:
    case 'FEE_TARGET_INVALID':
      return FeeTarget.FEE_TARGET_INVALID;
    case 1:
    case 'FEE_TARGET_SOURCE_AMOUNT':
      return FeeTarget.FEE_TARGET_SOURCE_AMOUNT;
    case 2:
    case 'FEE_TARGET_DESTINATION_AMOUNT':
      return FeeTarget.FEE_TARGET_DESTINATION_AMOUNT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FeeTarget.UNRECOGNIZED;
  }
}

export function feeTargetToJSON(object: FeeTarget): string {
  switch (object) {
    case FeeTarget.FEE_TARGET_INVALID:
      return 'FEE_TARGET_INVALID';
    case FeeTarget.FEE_TARGET_SOURCE_AMOUNT:
      return 'FEE_TARGET_SOURCE_AMOUNT';
    case FeeTarget.FEE_TARGET_DESTINATION_AMOUNT:
      return 'FEE_TARGET_DESTINATION_AMOUNT';
    case FeeTarget.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface FeeDefinition {
  kind: FeeKind;
  type: FeeType;
  amount: Money | undefined;
  percentage: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Fee {
  amount: Money | undefined;
  target: FeeTarget;
  definition: FeeDefinition | undefined;
  label: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Summary {
  original: ClientQuote | undefined;
  final: ClientQuote | undefined;
  fees: Fee[];
  totalFees: Money | undefined;
}

function createBaseFeeDefinition(): FeeDefinition {
  return { kind: 0, type: 0, amount: undefined, percentage: '' };
}

export const FeeDefinition = {
  encode(
    message: FeeDefinition,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.kind !== 0) {
      writer.uint32(8).int32(message.kind);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    if (message.amount !== undefined) {
      Money.encode(message.amount, writer.uint32(26).fork()).ldelim();
    }
    if (message.percentage !== '') {
      writer.uint32(34).string(message.percentage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FeeDefinition {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFeeDefinition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.kind = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.amount = Money.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.percentage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FeeDefinition {
    return {
      kind: isSet(object.kind) ? feeKindFromJSON(object.kind) : 0,
      type: isSet(object.type) ? feeTypeFromJSON(object.type) : 0,
      amount: isSet(object.amount) ? Money.fromJSON(object.amount) : undefined,
      percentage: isSet(object.percentage) ? String(object.percentage) : '',
    };
  },

  toJSON(message: FeeDefinition): unknown {
    const obj: any = {};
    message.kind !== undefined && (obj.kind = feeKindToJSON(message.kind));
    message.type !== undefined && (obj.type = feeTypeToJSON(message.type));
    message.amount !== undefined &&
      (obj.amount = message.amount ? Money.toJSON(message.amount) : undefined);
    message.percentage !== undefined && (obj.percentage = message.percentage);
    return obj;
  },

  create(base?: DeepPartial<FeeDefinition>): FeeDefinition {
    return FeeDefinition.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<FeeDefinition>): FeeDefinition {
    const message = createBaseFeeDefinition();
    message.kind = object.kind ?? 0;
    message.type = object.type ?? 0;
    message.amount =
      object.amount !== undefined && object.amount !== null
        ? Money.fromPartial(object.amount)
        : undefined;
    message.percentage = object.percentage ?? '';
    return message;
  },
};

function createBaseFee(): Fee {
  return { amount: undefined, target: 0, definition: undefined, label: '' };
}

export const Fee = {
  encode(message: Fee, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.amount !== undefined) {
      Money.encode(message.amount, writer.uint32(10).fork()).ldelim();
    }
    if (message.target !== 0) {
      writer.uint32(16).int32(message.target);
    }
    if (message.definition !== undefined) {
      FeeDefinition.encode(
        message.definition,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.label !== '') {
      writer.uint32(34).string(message.label);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Fee {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFee();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.amount = Money.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.target = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.definition = FeeDefinition.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.label = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Fee {
    return {
      amount: isSet(object.amount) ? Money.fromJSON(object.amount) : undefined,
      target: isSet(object.target) ? feeTargetFromJSON(object.target) : 0,
      definition: isSet(object.definition)
        ? FeeDefinition.fromJSON(object.definition)
        : undefined,
      label: isSet(object.label) ? String(object.label) : '',
    };
  },

  toJSON(message: Fee): unknown {
    const obj: any = {};
    message.amount !== undefined &&
      (obj.amount = message.amount ? Money.toJSON(message.amount) : undefined);
    message.target !== undefined &&
      (obj.target = feeTargetToJSON(message.target));
    message.definition !== undefined &&
      (obj.definition = message.definition
        ? FeeDefinition.toJSON(message.definition)
        : undefined);
    message.label !== undefined && (obj.label = message.label);
    return obj;
  },

  create(base?: DeepPartial<Fee>): Fee {
    return Fee.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Fee>): Fee {
    const message = createBaseFee();
    message.amount =
      object.amount !== undefined && object.amount !== null
        ? Money.fromPartial(object.amount)
        : undefined;
    message.target = object.target ?? 0;
    message.definition =
      object.definition !== undefined && object.definition !== null
        ? FeeDefinition.fromPartial(object.definition)
        : undefined;
    message.label = object.label ?? '';
    return message;
  },
};

function createBaseSummary(): Summary {
  return {
    original: undefined,
    final: undefined,
    fees: [],
    totalFees: undefined,
  };
}

export const Summary = {
  encode(
    message: Summary,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.original !== undefined) {
      ClientQuote.encode(message.original, writer.uint32(10).fork()).ldelim();
    }
    if (message.final !== undefined) {
      ClientQuote.encode(message.final, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.fees) {
      Fee.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.totalFees !== undefined) {
      Money.encode(message.totalFees, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Summary {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.original = ClientQuote.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.final = ClientQuote.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fees.push(Fee.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.totalFees = Money.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Summary {
    return {
      original: isSet(object.original)
        ? ClientQuote.fromJSON(object.original)
        : undefined,
      final: isSet(object.final)
        ? ClientQuote.fromJSON(object.final)
        : undefined,
      fees: Array.isArray(object?.fees)
        ? object.fees.map((e: any) => Fee.fromJSON(e))
        : [],
      totalFees: isSet(object.totalFees)
        ? Money.fromJSON(object.totalFees)
        : undefined,
    };
  },

  toJSON(message: Summary): unknown {
    const obj: any = {};
    message.original !== undefined &&
      (obj.original = message.original
        ? ClientQuote.toJSON(message.original)
        : undefined);
    message.final !== undefined &&
      (obj.final = message.final
        ? ClientQuote.toJSON(message.final)
        : undefined);
    if (message.fees) {
      obj.fees = message.fees.map((e) => (e ? Fee.toJSON(e) : undefined));
    } else {
      obj.fees = [];
    }
    message.totalFees !== undefined &&
      (obj.totalFees = message.totalFees
        ? Money.toJSON(message.totalFees)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<Summary>): Summary {
    return Summary.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Summary>): Summary {
    const message = createBaseSummary();
    message.original =
      object.original !== undefined && object.original !== null
        ? ClientQuote.fromPartial(object.original)
        : undefined;
    message.final =
      object.final !== undefined && object.final !== null
        ? ClientQuote.fromPartial(object.final)
        : undefined;
    message.fees = object.fees?.map((e) => Fee.fromPartial(e)) || [];
    message.totalFees =
      object.totalFees !== undefined && object.totalFees !== null
        ? Money.fromPartial(object.totalFees)
        : undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
