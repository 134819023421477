/* eslint-disable */
import _m0 from 'protobufjs/minimal';
import { Timestamp } from '../../../google/protobuf/timestamp';
import { Money } from '../fx/types';
import { Address, Name } from '../kyc/types';
import { ETA } from '../utils/types';
import {
  Wallet,
  WalletIcon,
  walletIconFromJSON,
  walletIconToJSON,
} from '../wallets/types';

export const protobufPackage = 'collections';

/**
 * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
 * buf:lint:ignore COMMENT_ENUM
 */
export enum PaymentMethodType {
  PAYMENT_METHOD_TYPE_CARD = 0,
  PAYMENT_METHOD_TYPE_BANK_TRANSFER = 1,
  PAYMENT_METHOD_TYPE_MOBILE_MONEY = 2,
  PAYMENT_METHOD_TYPE_EXTERNAL = 3,
  PAYMENT_METHOD_TYPE_APPLE_PAY = 4,
  PAYMENT_METHOD_TYPE_WALLET = 5,
  PAYMENT_METHOD_TYPE_GOOGLE_PAY = 6,
  PAYMENT_METHOD_TYPE_OFF_PLATFORM = 7,
  PAYMENT_METHOD_TYPE_IDEAL = 8,
  PAYMENT_METHOD_TYPE_SOFORT = 9,
  PAYMENT_METHOD_TYPE_MISSING = 10,
  UNRECOGNIZED = -1,
}

export function paymentMethodTypeFromJSON(object: any): PaymentMethodType {
  switch (object) {
    case 0:
    case 'PAYMENT_METHOD_TYPE_CARD':
      return PaymentMethodType.PAYMENT_METHOD_TYPE_CARD;
    case 1:
    case 'PAYMENT_METHOD_TYPE_BANK_TRANSFER':
      return PaymentMethodType.PAYMENT_METHOD_TYPE_BANK_TRANSFER;
    case 2:
    case 'PAYMENT_METHOD_TYPE_MOBILE_MONEY':
      return PaymentMethodType.PAYMENT_METHOD_TYPE_MOBILE_MONEY;
    case 3:
    case 'PAYMENT_METHOD_TYPE_EXTERNAL':
      return PaymentMethodType.PAYMENT_METHOD_TYPE_EXTERNAL;
    case 4:
    case 'PAYMENT_METHOD_TYPE_APPLE_PAY':
      return PaymentMethodType.PAYMENT_METHOD_TYPE_APPLE_PAY;
    case 5:
    case 'PAYMENT_METHOD_TYPE_WALLET':
      return PaymentMethodType.PAYMENT_METHOD_TYPE_WALLET;
    case 6:
    case 'PAYMENT_METHOD_TYPE_GOOGLE_PAY':
      return PaymentMethodType.PAYMENT_METHOD_TYPE_GOOGLE_PAY;
    case 7:
    case 'PAYMENT_METHOD_TYPE_OFF_PLATFORM':
      return PaymentMethodType.PAYMENT_METHOD_TYPE_OFF_PLATFORM;
    case 8:
    case 'PAYMENT_METHOD_TYPE_IDEAL':
      return PaymentMethodType.PAYMENT_METHOD_TYPE_IDEAL;
    case 9:
    case 'PAYMENT_METHOD_TYPE_SOFORT':
      return PaymentMethodType.PAYMENT_METHOD_TYPE_SOFORT;
    case 10:
    case 'PAYMENT_METHOD_TYPE_MISSING':
      return PaymentMethodType.PAYMENT_METHOD_TYPE_MISSING;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PaymentMethodType.UNRECOGNIZED;
  }
}

export function paymentMethodTypeToJSON(object: PaymentMethodType): string {
  switch (object) {
    case PaymentMethodType.PAYMENT_METHOD_TYPE_CARD:
      return 'PAYMENT_METHOD_TYPE_CARD';
    case PaymentMethodType.PAYMENT_METHOD_TYPE_BANK_TRANSFER:
      return 'PAYMENT_METHOD_TYPE_BANK_TRANSFER';
    case PaymentMethodType.PAYMENT_METHOD_TYPE_MOBILE_MONEY:
      return 'PAYMENT_METHOD_TYPE_MOBILE_MONEY';
    case PaymentMethodType.PAYMENT_METHOD_TYPE_EXTERNAL:
      return 'PAYMENT_METHOD_TYPE_EXTERNAL';
    case PaymentMethodType.PAYMENT_METHOD_TYPE_APPLE_PAY:
      return 'PAYMENT_METHOD_TYPE_APPLE_PAY';
    case PaymentMethodType.PAYMENT_METHOD_TYPE_WALLET:
      return 'PAYMENT_METHOD_TYPE_WALLET';
    case PaymentMethodType.PAYMENT_METHOD_TYPE_GOOGLE_PAY:
      return 'PAYMENT_METHOD_TYPE_GOOGLE_PAY';
    case PaymentMethodType.PAYMENT_METHOD_TYPE_OFF_PLATFORM:
      return 'PAYMENT_METHOD_TYPE_OFF_PLATFORM';
    case PaymentMethodType.PAYMENT_METHOD_TYPE_IDEAL:
      return 'PAYMENT_METHOD_TYPE_IDEAL';
    case PaymentMethodType.PAYMENT_METHOD_TYPE_SOFORT:
      return 'PAYMENT_METHOD_TYPE_SOFORT';
    case PaymentMethodType.PAYMENT_METHOD_TYPE_MISSING:
      return 'PAYMENT_METHOD_TYPE_MISSING';
    case PaymentMethodType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum CardScheme {
  CARD_SCHEME_INVALID = 0,
  CARD_SCHEME_MASTERCARD = 1,
  CARD_SCHEME_VISA = 2,
  UNRECOGNIZED = -1,
}

export function cardSchemeFromJSON(object: any): CardScheme {
  switch (object) {
    case 0:
    case 'CARD_SCHEME_INVALID':
      return CardScheme.CARD_SCHEME_INVALID;
    case 1:
    case 'CARD_SCHEME_MASTERCARD':
      return CardScheme.CARD_SCHEME_MASTERCARD;
    case 2:
    case 'CARD_SCHEME_VISA':
      return CardScheme.CARD_SCHEME_VISA;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CardScheme.UNRECOGNIZED;
  }
}

export function cardSchemeToJSON(object: CardScheme): string {
  switch (object) {
    case CardScheme.CARD_SCHEME_INVALID:
      return 'CARD_SCHEME_INVALID';
    case CardScheme.CARD_SCHEME_MASTERCARD:
      return 'CARD_SCHEME_MASTERCARD';
    case CardScheme.CARD_SCHEME_VISA:
      return 'CARD_SCHEME_VISA';
    case CardScheme.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum CardWallet {
  CARD_WALLET_INVALID = 0,
  CARD_WALLET_APPLE_PAY = 1,
  CARD_WALLET_GOOGLE_PAY = 2,
  UNRECOGNIZED = -1,
}

export function cardWalletFromJSON(object: any): CardWallet {
  switch (object) {
    case 0:
    case 'CARD_WALLET_INVALID':
      return CardWallet.CARD_WALLET_INVALID;
    case 1:
    case 'CARD_WALLET_APPLE_PAY':
      return CardWallet.CARD_WALLET_APPLE_PAY;
    case 2:
    case 'CARD_WALLET_GOOGLE_PAY':
      return CardWallet.CARD_WALLET_GOOGLE_PAY;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CardWallet.UNRECOGNIZED;
  }
}

export function cardWalletToJSON(object: CardWallet): string {
  switch (object) {
    case CardWallet.CARD_WALLET_INVALID:
      return 'CARD_WALLET_INVALID';
    case CardWallet.CARD_WALLET_APPLE_PAY:
      return 'CARD_WALLET_APPLE_PAY';
    case CardWallet.CARD_WALLET_GOOGLE_PAY:
      return 'CARD_WALLET_GOOGLE_PAY';
    case CardWallet.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * To be used when initiating an Apple Pay payment
 * buf:lint:ignore COMMENT_ENUM
 */
export enum MerchantCapability {
  MERCHANT_CAPABILITY_INVALID = 0,
  MERCHANT_CAPABILITY_CREDIT_CARD = 1,
  MERCHANT_CAPABILITY_DEBIT_CARD = 2,
  MERCHANT_CAPABILITY_3DS = 3,
  UNRECOGNIZED = -1,
}

export function merchantCapabilityFromJSON(object: any): MerchantCapability {
  switch (object) {
    case 0:
    case 'MERCHANT_CAPABILITY_INVALID':
      return MerchantCapability.MERCHANT_CAPABILITY_INVALID;
    case 1:
    case 'MERCHANT_CAPABILITY_CREDIT_CARD':
      return MerchantCapability.MERCHANT_CAPABILITY_CREDIT_CARD;
    case 2:
    case 'MERCHANT_CAPABILITY_DEBIT_CARD':
      return MerchantCapability.MERCHANT_CAPABILITY_DEBIT_CARD;
    case 3:
    case 'MERCHANT_CAPABILITY_3DS':
      return MerchantCapability.MERCHANT_CAPABILITY_3DS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return MerchantCapability.UNRECOGNIZED;
  }
}

export function merchantCapabilityToJSON(object: MerchantCapability): string {
  switch (object) {
    case MerchantCapability.MERCHANT_CAPABILITY_INVALID:
      return 'MERCHANT_CAPABILITY_INVALID';
    case MerchantCapability.MERCHANT_CAPABILITY_CREDIT_CARD:
      return 'MERCHANT_CAPABILITY_CREDIT_CARD';
    case MerchantCapability.MERCHANT_CAPABILITY_DEBIT_CARD:
      return 'MERCHANT_CAPABILITY_DEBIT_CARD';
    case MerchantCapability.MERCHANT_CAPABILITY_3DS:
      return 'MERCHANT_CAPABILITY_3DS';
    case MerchantCapability.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum FundingAccount {
  FUNDING_ACCOUNT_INVALID = 0,
  FUNDING_ACCOUNT_PESASWAP_KENYA_KES = 1,
  FUNDING_ACCOUNT_VERTO_FX_USD = 2,
  FUNDING_ACCOUNT_VERTO_FX_GBP = 3,
  FUNDING_ACCOUNT_CELLULANT_KENYA_KES = 4,
  FUNDING_ACCOUNT_CELLULANT_KENYA_USD = 5,
  FUNDING_ACCOUNT_CITI_BANK_KENYA_KES = 6,
  FUNDING_ACCOUNT_CITI_BANK_KENYA_USD = 7,
  FUNDING_ACCOUNT_CITI_BANK_KENYA_GBP = 8,
  FUNDING_ACCOUNT_EQUITY_BANK_KENYA_USD = 9,
  FUNDING_ACCOUNT_EQUITY_BANK_KENYA_KES = 10,
  FUNDING_ACCOUNT_EQUITY_BANK_KENYA_GBP = 11,
  FUNDING_ACCOUNT_KAPA_OIL_REFINERIES_USD = 12,
  FUNDING_ACCOUNT_DUKAPAY_LIMITED_KES = 13,
  FUNDING_ACCOUNT_UBA_BANK_KENYA_KES = 14,
  FUNDING_ACCOUNT_UBA_BANK_KENYA_USD = 15,
  FUNDING_ACCOUNT_UBA_BANK_KENYA_GBP = 16,
  FUNDING_ACCOUNT_UBA_BANK_KENYA_EUR = 17,
  FUNDING_ACCOUNT_EVOLVE_BANK_TRUST_USA = 18,
  FUNDING_ACCOUNT_MODULR_FINANCE_LIMITED_GBP = 19,
  FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_TZS = 20,
  FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_USD = 21,
  FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_GBP = 22,
  FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_EUR = 23,
  FUNDING_ACCOUNT_ECO_BANK_TANZANIA_TZS = 24,
  FUNDING_ACCOUNT_ECO_BANK_TANZANIA_USD = 25,
  FUNDING_ACCOUNT_ECO_BANK_TANZANIA_NOSTRO_GBP = 26,
  FUNDING_ACCOUNT_CELLULANT_TANZANIA_TZS = 27,
  FUNDING_ACCOUNT_SELCOM_TANZANIA_TZS = 28,
  FUNDING_ACCOUNT_FINCRA_TECHNOLOGIES_LIMITED_GBP = 29,
  FUNDING_ACCOUNT_FLUTTERWAVE_GBP = 30,
  FUNDING_ACCOUNT_FLUTTERWAVE_USD = 31,
  FUNDING_ACCOUNT_EQUITY_BANK_UGANDA_UGX = 32,
  FUNDING_ACCOUNT_PEGASUS_TECHNOLOGIES_LIMITED_UGX = 33,
  FUNDING_ACCOUNT_INTERSWITCH_EAST_AFRICA_LTD_UGX = 34,
  FUNDING_ACCOUNT_AZA_FINANCE_LIMITED_GBP = 35,
  FUNDING_ACCOUNT_ESICIA_LIMITED_USD = 36,
  FUNDING_ACCOUNT_BIG_PAY_USD = 37,
  FUNDING_ACCOUNT_BIG_PAY_GHANA_LIMITED_GHS = 38,
  FUNDING_ACCOUNT_OBSIDIAN_ACHERNAR_LTD_GBP = 39,
  UNRECOGNIZED = -1,
}

export function fundingAccountFromJSON(object: any): FundingAccount {
  switch (object) {
    case 0:
    case 'FUNDING_ACCOUNT_INVALID':
      return FundingAccount.FUNDING_ACCOUNT_INVALID;
    case 1:
    case 'FUNDING_ACCOUNT_PESASWAP_KENYA_KES':
      return FundingAccount.FUNDING_ACCOUNT_PESASWAP_KENYA_KES;
    case 2:
    case 'FUNDING_ACCOUNT_VERTO_FX_USD':
      return FundingAccount.FUNDING_ACCOUNT_VERTO_FX_USD;
    case 3:
    case 'FUNDING_ACCOUNT_VERTO_FX_GBP':
      return FundingAccount.FUNDING_ACCOUNT_VERTO_FX_GBP;
    case 4:
    case 'FUNDING_ACCOUNT_CELLULANT_KENYA_KES':
      return FundingAccount.FUNDING_ACCOUNT_CELLULANT_KENYA_KES;
    case 5:
    case 'FUNDING_ACCOUNT_CELLULANT_KENYA_USD':
      return FundingAccount.FUNDING_ACCOUNT_CELLULANT_KENYA_USD;
    case 6:
    case 'FUNDING_ACCOUNT_CITI_BANK_KENYA_KES':
      return FundingAccount.FUNDING_ACCOUNT_CITI_BANK_KENYA_KES;
    case 7:
    case 'FUNDING_ACCOUNT_CITI_BANK_KENYA_USD':
      return FundingAccount.FUNDING_ACCOUNT_CITI_BANK_KENYA_USD;
    case 8:
    case 'FUNDING_ACCOUNT_CITI_BANK_KENYA_GBP':
      return FundingAccount.FUNDING_ACCOUNT_CITI_BANK_KENYA_GBP;
    case 9:
    case 'FUNDING_ACCOUNT_EQUITY_BANK_KENYA_USD':
      return FundingAccount.FUNDING_ACCOUNT_EQUITY_BANK_KENYA_USD;
    case 10:
    case 'FUNDING_ACCOUNT_EQUITY_BANK_KENYA_KES':
      return FundingAccount.FUNDING_ACCOUNT_EQUITY_BANK_KENYA_KES;
    case 11:
    case 'FUNDING_ACCOUNT_EQUITY_BANK_KENYA_GBP':
      return FundingAccount.FUNDING_ACCOUNT_EQUITY_BANK_KENYA_GBP;
    case 12:
    case 'FUNDING_ACCOUNT_KAPA_OIL_REFINERIES_USD':
      return FundingAccount.FUNDING_ACCOUNT_KAPA_OIL_REFINERIES_USD;
    case 13:
    case 'FUNDING_ACCOUNT_DUKAPAY_LIMITED_KES':
      return FundingAccount.FUNDING_ACCOUNT_DUKAPAY_LIMITED_KES;
    case 14:
    case 'FUNDING_ACCOUNT_UBA_BANK_KENYA_KES':
      return FundingAccount.FUNDING_ACCOUNT_UBA_BANK_KENYA_KES;
    case 15:
    case 'FUNDING_ACCOUNT_UBA_BANK_KENYA_USD':
      return FundingAccount.FUNDING_ACCOUNT_UBA_BANK_KENYA_USD;
    case 16:
    case 'FUNDING_ACCOUNT_UBA_BANK_KENYA_GBP':
      return FundingAccount.FUNDING_ACCOUNT_UBA_BANK_KENYA_GBP;
    case 17:
    case 'FUNDING_ACCOUNT_UBA_BANK_KENYA_EUR':
      return FundingAccount.FUNDING_ACCOUNT_UBA_BANK_KENYA_EUR;
    case 18:
    case 'FUNDING_ACCOUNT_EVOLVE_BANK_TRUST_USA':
      return FundingAccount.FUNDING_ACCOUNT_EVOLVE_BANK_TRUST_USA;
    case 19:
    case 'FUNDING_ACCOUNT_MODULR_FINANCE_LIMITED_GBP':
      return FundingAccount.FUNDING_ACCOUNT_MODULR_FINANCE_LIMITED_GBP;
    case 20:
    case 'FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_TZS':
      return FundingAccount.FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_TZS;
    case 21:
    case 'FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_USD':
      return FundingAccount.FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_USD;
    case 22:
    case 'FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_GBP':
      return FundingAccount.FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_GBP;
    case 23:
    case 'FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_EUR':
      return FundingAccount.FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_EUR;
    case 24:
    case 'FUNDING_ACCOUNT_ECO_BANK_TANZANIA_TZS':
      return FundingAccount.FUNDING_ACCOUNT_ECO_BANK_TANZANIA_TZS;
    case 25:
    case 'FUNDING_ACCOUNT_ECO_BANK_TANZANIA_USD':
      return FundingAccount.FUNDING_ACCOUNT_ECO_BANK_TANZANIA_USD;
    case 26:
    case 'FUNDING_ACCOUNT_ECO_BANK_TANZANIA_NOSTRO_GBP':
      return FundingAccount.FUNDING_ACCOUNT_ECO_BANK_TANZANIA_NOSTRO_GBP;
    case 27:
    case 'FUNDING_ACCOUNT_CELLULANT_TANZANIA_TZS':
      return FundingAccount.FUNDING_ACCOUNT_CELLULANT_TANZANIA_TZS;
    case 28:
    case 'FUNDING_ACCOUNT_SELCOM_TANZANIA_TZS':
      return FundingAccount.FUNDING_ACCOUNT_SELCOM_TANZANIA_TZS;
    case 29:
    case 'FUNDING_ACCOUNT_FINCRA_TECHNOLOGIES_LIMITED_GBP':
      return FundingAccount.FUNDING_ACCOUNT_FINCRA_TECHNOLOGIES_LIMITED_GBP;
    case 30:
    case 'FUNDING_ACCOUNT_FLUTTERWAVE_GBP':
      return FundingAccount.FUNDING_ACCOUNT_FLUTTERWAVE_GBP;
    case 31:
    case 'FUNDING_ACCOUNT_FLUTTERWAVE_USD':
      return FundingAccount.FUNDING_ACCOUNT_FLUTTERWAVE_USD;
    case 32:
    case 'FUNDING_ACCOUNT_EQUITY_BANK_UGANDA_UGX':
      return FundingAccount.FUNDING_ACCOUNT_EQUITY_BANK_UGANDA_UGX;
    case 33:
    case 'FUNDING_ACCOUNT_PEGASUS_TECHNOLOGIES_LIMITED_UGX':
      return FundingAccount.FUNDING_ACCOUNT_PEGASUS_TECHNOLOGIES_LIMITED_UGX;
    case 34:
    case 'FUNDING_ACCOUNT_INTERSWITCH_EAST_AFRICA_LTD_UGX':
      return FundingAccount.FUNDING_ACCOUNT_INTERSWITCH_EAST_AFRICA_LTD_UGX;
    case 35:
    case 'FUNDING_ACCOUNT_AZA_FINANCE_LIMITED_GBP':
      return FundingAccount.FUNDING_ACCOUNT_AZA_FINANCE_LIMITED_GBP;
    case 36:
    case 'FUNDING_ACCOUNT_ESICIA_LIMITED_USD':
      return FundingAccount.FUNDING_ACCOUNT_ESICIA_LIMITED_USD;
    case 37:
    case 'FUNDING_ACCOUNT_BIG_PAY_USD':
      return FundingAccount.FUNDING_ACCOUNT_BIG_PAY_USD;
    case 38:
    case 'FUNDING_ACCOUNT_BIG_PAY_GHANA_LIMITED_GHS':
      return FundingAccount.FUNDING_ACCOUNT_BIG_PAY_GHANA_LIMITED_GHS;
    case 39:
    case 'FUNDING_ACCOUNT_OBSIDIAN_ACHERNAR_LTD_GBP':
      return FundingAccount.FUNDING_ACCOUNT_OBSIDIAN_ACHERNAR_LTD_GBP;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FundingAccount.UNRECOGNIZED;
  }
}

export function fundingAccountToJSON(object: FundingAccount): string {
  switch (object) {
    case FundingAccount.FUNDING_ACCOUNT_INVALID:
      return 'FUNDING_ACCOUNT_INVALID';
    case FundingAccount.FUNDING_ACCOUNT_PESASWAP_KENYA_KES:
      return 'FUNDING_ACCOUNT_PESASWAP_KENYA_KES';
    case FundingAccount.FUNDING_ACCOUNT_VERTO_FX_USD:
      return 'FUNDING_ACCOUNT_VERTO_FX_USD';
    case FundingAccount.FUNDING_ACCOUNT_VERTO_FX_GBP:
      return 'FUNDING_ACCOUNT_VERTO_FX_GBP';
    case FundingAccount.FUNDING_ACCOUNT_CELLULANT_KENYA_KES:
      return 'FUNDING_ACCOUNT_CELLULANT_KENYA_KES';
    case FundingAccount.FUNDING_ACCOUNT_CELLULANT_KENYA_USD:
      return 'FUNDING_ACCOUNT_CELLULANT_KENYA_USD';
    case FundingAccount.FUNDING_ACCOUNT_CITI_BANK_KENYA_KES:
      return 'FUNDING_ACCOUNT_CITI_BANK_KENYA_KES';
    case FundingAccount.FUNDING_ACCOUNT_CITI_BANK_KENYA_USD:
      return 'FUNDING_ACCOUNT_CITI_BANK_KENYA_USD';
    case FundingAccount.FUNDING_ACCOUNT_CITI_BANK_KENYA_GBP:
      return 'FUNDING_ACCOUNT_CITI_BANK_KENYA_GBP';
    case FundingAccount.FUNDING_ACCOUNT_EQUITY_BANK_KENYA_USD:
      return 'FUNDING_ACCOUNT_EQUITY_BANK_KENYA_USD';
    case FundingAccount.FUNDING_ACCOUNT_EQUITY_BANK_KENYA_KES:
      return 'FUNDING_ACCOUNT_EQUITY_BANK_KENYA_KES';
    case FundingAccount.FUNDING_ACCOUNT_EQUITY_BANK_KENYA_GBP:
      return 'FUNDING_ACCOUNT_EQUITY_BANK_KENYA_GBP';
    case FundingAccount.FUNDING_ACCOUNT_KAPA_OIL_REFINERIES_USD:
      return 'FUNDING_ACCOUNT_KAPA_OIL_REFINERIES_USD';
    case FundingAccount.FUNDING_ACCOUNT_DUKAPAY_LIMITED_KES:
      return 'FUNDING_ACCOUNT_DUKAPAY_LIMITED_KES';
    case FundingAccount.FUNDING_ACCOUNT_UBA_BANK_KENYA_KES:
      return 'FUNDING_ACCOUNT_UBA_BANK_KENYA_KES';
    case FundingAccount.FUNDING_ACCOUNT_UBA_BANK_KENYA_USD:
      return 'FUNDING_ACCOUNT_UBA_BANK_KENYA_USD';
    case FundingAccount.FUNDING_ACCOUNT_UBA_BANK_KENYA_GBP:
      return 'FUNDING_ACCOUNT_UBA_BANK_KENYA_GBP';
    case FundingAccount.FUNDING_ACCOUNT_UBA_BANK_KENYA_EUR:
      return 'FUNDING_ACCOUNT_UBA_BANK_KENYA_EUR';
    case FundingAccount.FUNDING_ACCOUNT_EVOLVE_BANK_TRUST_USA:
      return 'FUNDING_ACCOUNT_EVOLVE_BANK_TRUST_USA';
    case FundingAccount.FUNDING_ACCOUNT_MODULR_FINANCE_LIMITED_GBP:
      return 'FUNDING_ACCOUNT_MODULR_FINANCE_LIMITED_GBP';
    case FundingAccount.FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_TZS:
      return 'FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_TZS';
    case FundingAccount.FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_USD:
      return 'FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_USD';
    case FundingAccount.FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_GBP:
      return 'FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_GBP';
    case FundingAccount.FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_EUR:
      return 'FUNDING_ACCOUNT_EQUITY_BANK_TANZANIA_EUR';
    case FundingAccount.FUNDING_ACCOUNT_ECO_BANK_TANZANIA_TZS:
      return 'FUNDING_ACCOUNT_ECO_BANK_TANZANIA_TZS';
    case FundingAccount.FUNDING_ACCOUNT_ECO_BANK_TANZANIA_USD:
      return 'FUNDING_ACCOUNT_ECO_BANK_TANZANIA_USD';
    case FundingAccount.FUNDING_ACCOUNT_ECO_BANK_TANZANIA_NOSTRO_GBP:
      return 'FUNDING_ACCOUNT_ECO_BANK_TANZANIA_NOSTRO_GBP';
    case FundingAccount.FUNDING_ACCOUNT_CELLULANT_TANZANIA_TZS:
      return 'FUNDING_ACCOUNT_CELLULANT_TANZANIA_TZS';
    case FundingAccount.FUNDING_ACCOUNT_SELCOM_TANZANIA_TZS:
      return 'FUNDING_ACCOUNT_SELCOM_TANZANIA_TZS';
    case FundingAccount.FUNDING_ACCOUNT_FINCRA_TECHNOLOGIES_LIMITED_GBP:
      return 'FUNDING_ACCOUNT_FINCRA_TECHNOLOGIES_LIMITED_GBP';
    case FundingAccount.FUNDING_ACCOUNT_FLUTTERWAVE_GBP:
      return 'FUNDING_ACCOUNT_FLUTTERWAVE_GBP';
    case FundingAccount.FUNDING_ACCOUNT_FLUTTERWAVE_USD:
      return 'FUNDING_ACCOUNT_FLUTTERWAVE_USD';
    case FundingAccount.FUNDING_ACCOUNT_EQUITY_BANK_UGANDA_UGX:
      return 'FUNDING_ACCOUNT_EQUITY_BANK_UGANDA_UGX';
    case FundingAccount.FUNDING_ACCOUNT_PEGASUS_TECHNOLOGIES_LIMITED_UGX:
      return 'FUNDING_ACCOUNT_PEGASUS_TECHNOLOGIES_LIMITED_UGX';
    case FundingAccount.FUNDING_ACCOUNT_INTERSWITCH_EAST_AFRICA_LTD_UGX:
      return 'FUNDING_ACCOUNT_INTERSWITCH_EAST_AFRICA_LTD_UGX';
    case FundingAccount.FUNDING_ACCOUNT_AZA_FINANCE_LIMITED_GBP:
      return 'FUNDING_ACCOUNT_AZA_FINANCE_LIMITED_GBP';
    case FundingAccount.FUNDING_ACCOUNT_ESICIA_LIMITED_USD:
      return 'FUNDING_ACCOUNT_ESICIA_LIMITED_USD';
    case FundingAccount.FUNDING_ACCOUNT_BIG_PAY_USD:
      return 'FUNDING_ACCOUNT_BIG_PAY_USD';
    case FundingAccount.FUNDING_ACCOUNT_BIG_PAY_GHANA_LIMITED_GHS:
      return 'FUNDING_ACCOUNT_BIG_PAY_GHANA_LIMITED_GHS';
    case FundingAccount.FUNDING_ACCOUNT_OBSIDIAN_ACHERNAR_LTD_GBP:
      return 'FUNDING_ACCOUNT_OBSIDIAN_ACHERNAR_LTD_GBP';
    case FundingAccount.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum RequirementType {
  REQUIREMENT_TYPE_INVALID = 0,
  REQUIREMENT_TYPE_AMOUNT = 1,
  REQUIREMENT_TYPE_CURRENT_TIME = 2,
  UNRECOGNIZED = -1,
}

export function requirementTypeFromJSON(object: any): RequirementType {
  switch (object) {
    case 0:
    case 'REQUIREMENT_TYPE_INVALID':
      return RequirementType.REQUIREMENT_TYPE_INVALID;
    case 1:
    case 'REQUIREMENT_TYPE_AMOUNT':
      return RequirementType.REQUIREMENT_TYPE_AMOUNT;
    case 2:
    case 'REQUIREMENT_TYPE_CURRENT_TIME':
      return RequirementType.REQUIREMENT_TYPE_CURRENT_TIME;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return RequirementType.UNRECOGNIZED;
  }
}

export function requirementTypeToJSON(object: RequirementType): string {
  switch (object) {
    case RequirementType.REQUIREMENT_TYPE_INVALID:
      return 'REQUIREMENT_TYPE_INVALID';
    case RequirementType.REQUIREMENT_TYPE_AMOUNT:
      return 'REQUIREMENT_TYPE_AMOUNT';
    case RequirementType.REQUIREMENT_TYPE_CURRENT_TIME:
      return 'REQUIREMENT_TYPE_CURRENT_TIME';
    case RequirementType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface PaymentMethod {
  type: PaymentMethodType;
  /**
   * use `method` instead
   *
   * @deprecated
   */
  details: { [key: string]: string };
  card?: PaymentMethodCard | undefined;
  bankAccount?: PaymentMethodBankAccount | undefined;
  /** balance should be ignored */
  wallet?: Wallet | undefined;
}

export interface PaymentMethod_DetailsEntry {
  key: string;
  value: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface PaymentMethodCardFlags {
  bypass3ds: boolean;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface PaymentMethodCard {
  id: string;
  maskedPan: string;
  expiry: string;
  nameOnCard: string;
  /** @deprecated */
  deprecatedScheme: string;
  fee: Money | undefined;
  lastUsedAt: Timestamp | undefined;
  bankName: string;
  /** @deprecated */
  deprecatedWallet: string;
  providerName: string;
  active: boolean;
  reusable: boolean;
  flags: PaymentMethodCardFlags | undefined;
  scheme: CardScheme;
  wallet: CardWallet;
  eta: ETA | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface PaymentMethodBankTransfer {
  id: string;
  reference: string;
  accountDetails: { [key: string]: string };
  bankName: string;
  bankAddress: Address | undefined;
  fee: Money | undefined;
  lastUsedAt: Timestamp | undefined;
  accountId: string;
  eta: ETA | undefined;
  providerName: string;
}

export interface PaymentMethodBankTransfer_AccountDetailsEntry {
  key: string;
  value: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface PaymentMethodBankAccount {
  id: string;
  name: Name | undefined;
  type: string;
  data: { [key: string]: string };
  whiteListed: boolean;
  lastUsedAt: Timestamp | undefined;
  providerName: string;
  invalidDetails: boolean;
  eta: ETA | undefined;
}

export interface PaymentMethodBankAccount_DataEntry {
  key: string;
  value: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface AdditionalPaymentMethod {
  type: PaymentMethodType;
  providerName: string;
  providerData: { [key: string]: string };
  needsToken: boolean;
  eta: ETA | undefined;
}

export interface AdditionalPaymentMethod_ProviderDataEntry {
  key: string;
  value: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface LastUsedPaymentMethod {
  type: PaymentMethodType;
  providerName: string;
  methodId: string;
}

/** Nala wallet. */
export interface PaymentMethodWallet {
  id: string;
  name: string;
  balance: Money | undefined;
  icon: WalletIcon;
  eta: ETA | undefined;
  details: Wallet | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface CardData {
  number: string;
  expiry: string;
  cvv: string;
}

/** PaymentSummary is to be used to initiate an Apple/Google Pay payment. */
export interface PaymentSummary {
  /** Does not include the total. */
  items: PaymentSummaryItem[];
  total: PaymentSummaryItem | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface PaymentSummaryItem {
  label: string;
  amount: Money | undefined;
}

/** PaymentCapabilities is to be used to initiate an Apple Pay payment */
export interface PaymentCapabilities {
  merchantCapabilities: MerchantCapability[];
  /** ie. payment networks */
  cardSchemes: CardScheme[];
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface PaymentMethodOffPlatform {
  name: string;
  currency: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface RequirementAmount {
  min: Money | undefined;
  max: Money | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface RequirementCurrentTime {
  min: Timestamp | undefined;
  max: Timestamp | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Requirement {
  type: RequirementType;
  description: string;
  amount?: RequirementAmount | undefined;
  currentTime?: RequirementCurrentTime | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface PaymentSource {
  type: PaymentMethodType;
  wallet?: PaymentMethodWallet | undefined;
  offPlatform?: PaymentMethodOffPlatform | undefined;
  requirements: Requirement[];
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface FundingOption {
  displayName: string;
  fundingAccountId: string;
  currency: string;
  country: string;
}

function createBasePaymentMethod(): PaymentMethod {
  return {
    type: 0,
    details: {},
    card: undefined,
    bankAccount: undefined,
    wallet: undefined,
  };
}

export const PaymentMethod = {
  encode(
    message: PaymentMethod,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    Object.entries(message.details).forEach(([key, value]) => {
      PaymentMethod_DetailsEntry.encode(
        { key: key as any, value },
        writer.uint32(18).fork(),
      ).ldelim();
    });
    if (message.card !== undefined) {
      PaymentMethodCard.encode(message.card, writer.uint32(26).fork()).ldelim();
    }
    if (message.bankAccount !== undefined) {
      PaymentMethodBankAccount.encode(
        message.bankAccount,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.wallet !== undefined) {
      Wallet.encode(message.wallet, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaymentMethod {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentMethod();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = PaymentMethod_DetailsEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry2.value !== undefined) {
            message.details[entry2.key] = entry2.value;
          }
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.card = PaymentMethodCard.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.bankAccount = PaymentMethodBankAccount.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.wallet = Wallet.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentMethod {
    return {
      type: isSet(object.type) ? paymentMethodTypeFromJSON(object.type) : 0,
      details: isObject(object.details)
        ? Object.entries(object.details).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
      card: isSet(object.card)
        ? PaymentMethodCard.fromJSON(object.card)
        : undefined,
      bankAccount: isSet(object.bankAccount)
        ? PaymentMethodBankAccount.fromJSON(object.bankAccount)
        : undefined,
      wallet: isSet(object.wallet) ? Wallet.fromJSON(object.wallet) : undefined,
    };
  },

  toJSON(message: PaymentMethod): unknown {
    const obj: any = {};
    message.type !== undefined &&
      (obj.type = paymentMethodTypeToJSON(message.type));
    obj.details = {};
    if (message.details) {
      Object.entries(message.details).forEach(([k, v]) => {
        obj.details[k] = v;
      });
    }
    message.card !== undefined &&
      (obj.card = message.card
        ? PaymentMethodCard.toJSON(message.card)
        : undefined);
    message.bankAccount !== undefined &&
      (obj.bankAccount = message.bankAccount
        ? PaymentMethodBankAccount.toJSON(message.bankAccount)
        : undefined);
    message.wallet !== undefined &&
      (obj.wallet = message.wallet ? Wallet.toJSON(message.wallet) : undefined);
    return obj;
  },

  create(base?: DeepPartial<PaymentMethod>): PaymentMethod {
    return PaymentMethod.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<PaymentMethod>): PaymentMethod {
    const message = createBasePaymentMethod();
    message.type = object.type ?? 0;
    message.details = Object.entries(object.details ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.card =
      object.card !== undefined && object.card !== null
        ? PaymentMethodCard.fromPartial(object.card)
        : undefined;
    message.bankAccount =
      object.bankAccount !== undefined && object.bankAccount !== null
        ? PaymentMethodBankAccount.fromPartial(object.bankAccount)
        : undefined;
    message.wallet =
      object.wallet !== undefined && object.wallet !== null
        ? Wallet.fromPartial(object.wallet)
        : undefined;
    return message;
  },
};

function createBasePaymentMethod_DetailsEntry(): PaymentMethod_DetailsEntry {
  return { key: '', value: '' };
}

export const PaymentMethod_DetailsEntry = {
  encode(
    message: PaymentMethod_DetailsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PaymentMethod_DetailsEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentMethod_DetailsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentMethod_DetailsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: PaymentMethod_DetailsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(
    base?: DeepPartial<PaymentMethod_DetailsEntry>,
  ): PaymentMethod_DetailsEntry {
    return PaymentMethod_DetailsEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<PaymentMethod_DetailsEntry>,
  ): PaymentMethod_DetailsEntry {
    const message = createBasePaymentMethod_DetailsEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBasePaymentMethodCardFlags(): PaymentMethodCardFlags {
  return { bypass3ds: false };
}

export const PaymentMethodCardFlags = {
  encode(
    message: PaymentMethodCardFlags,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.bypass3ds === true) {
      writer.uint32(8).bool(message.bypass3ds);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PaymentMethodCardFlags {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentMethodCardFlags();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.bypass3ds = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentMethodCardFlags {
    return {
      bypass3ds: isSet(object.bypass3ds) ? Boolean(object.bypass3ds) : false,
    };
  },

  toJSON(message: PaymentMethodCardFlags): unknown {
    const obj: any = {};
    message.bypass3ds !== undefined && (obj.bypass3ds = message.bypass3ds);
    return obj;
  },

  create(base?: DeepPartial<PaymentMethodCardFlags>): PaymentMethodCardFlags {
    return PaymentMethodCardFlags.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<PaymentMethodCardFlags>,
  ): PaymentMethodCardFlags {
    const message = createBasePaymentMethodCardFlags();
    message.bypass3ds = object.bypass3ds ?? false;
    return message;
  },
};

function createBasePaymentMethodCard(): PaymentMethodCard {
  return {
    id: '',
    maskedPan: '',
    expiry: '',
    nameOnCard: '',
    deprecatedScheme: '',
    fee: undefined,
    lastUsedAt: undefined,
    bankName: '',
    deprecatedWallet: '',
    providerName: '',
    active: false,
    reusable: false,
    flags: undefined,
    scheme: 0,
    wallet: 0,
    eta: undefined,
  };
}

export const PaymentMethodCard = {
  encode(
    message: PaymentMethodCard,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.maskedPan !== '') {
      writer.uint32(18).string(message.maskedPan);
    }
    if (message.expiry !== '') {
      writer.uint32(26).string(message.expiry);
    }
    if (message.nameOnCard !== '') {
      writer.uint32(34).string(message.nameOnCard);
    }
    if (message.deprecatedScheme !== '') {
      writer.uint32(42).string(message.deprecatedScheme);
    }
    if (message.fee !== undefined) {
      Money.encode(message.fee, writer.uint32(50).fork()).ldelim();
    }
    if (message.lastUsedAt !== undefined) {
      Timestamp.encode(message.lastUsedAt, writer.uint32(58).fork()).ldelim();
    }
    if (message.bankName !== '') {
      writer.uint32(66).string(message.bankName);
    }
    if (message.deprecatedWallet !== '') {
      writer.uint32(74).string(message.deprecatedWallet);
    }
    if (message.providerName !== '') {
      writer.uint32(82).string(message.providerName);
    }
    if (message.active === true) {
      writer.uint32(88).bool(message.active);
    }
    if (message.reusable === true) {
      writer.uint32(96).bool(message.reusable);
    }
    if (message.flags !== undefined) {
      PaymentMethodCardFlags.encode(
        message.flags,
        writer.uint32(106).fork(),
      ).ldelim();
    }
    if (message.scheme !== 0) {
      writer.uint32(112).int32(message.scheme);
    }
    if (message.wallet !== 0) {
      writer.uint32(120).int32(message.wallet);
    }
    if (message.eta !== undefined) {
      ETA.encode(message.eta, writer.uint32(130).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaymentMethodCard {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentMethodCard();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.maskedPan = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.expiry = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.nameOnCard = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.deprecatedScheme = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fee = Money.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.lastUsedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.bankName = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.deprecatedWallet = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.providerName = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.reusable = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.flags = PaymentMethodCardFlags.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.scheme = reader.int32() as any;
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.wallet = reader.int32() as any;
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.eta = ETA.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentMethodCard {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      maskedPan: isSet(object.maskedPan) ? String(object.maskedPan) : '',
      expiry: isSet(object.expiry) ? String(object.expiry) : '',
      nameOnCard: isSet(object.nameOnCard) ? String(object.nameOnCard) : '',
      deprecatedScheme: isSet(object.deprecatedScheme)
        ? String(object.deprecatedScheme)
        : '',
      fee: isSet(object.fee) ? Money.fromJSON(object.fee) : undefined,
      lastUsedAt: isSet(object.lastUsedAt)
        ? fromJsonTimestamp(object.lastUsedAt)
        : undefined,
      bankName: isSet(object.bankName) ? String(object.bankName) : '',
      deprecatedWallet: isSet(object.deprecatedWallet)
        ? String(object.deprecatedWallet)
        : '',
      providerName: isSet(object.providerName)
        ? String(object.providerName)
        : '',
      active: isSet(object.active) ? Boolean(object.active) : false,
      reusable: isSet(object.reusable) ? Boolean(object.reusable) : false,
      flags: isSet(object.flags)
        ? PaymentMethodCardFlags.fromJSON(object.flags)
        : undefined,
      scheme: isSet(object.scheme) ? cardSchemeFromJSON(object.scheme) : 0,
      wallet: isSet(object.wallet) ? cardWalletFromJSON(object.wallet) : 0,
      eta: isSet(object.eta) ? ETA.fromJSON(object.eta) : undefined,
    };
  },

  toJSON(message: PaymentMethodCard): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.maskedPan !== undefined && (obj.maskedPan = message.maskedPan);
    message.expiry !== undefined && (obj.expiry = message.expiry);
    message.nameOnCard !== undefined && (obj.nameOnCard = message.nameOnCard);
    message.deprecatedScheme !== undefined &&
      (obj.deprecatedScheme = message.deprecatedScheme);
    message.fee !== undefined &&
      (obj.fee = message.fee ? Money.toJSON(message.fee) : undefined);
    message.lastUsedAt !== undefined &&
      (obj.lastUsedAt = fromTimestamp(message.lastUsedAt).toISOString());
    message.bankName !== undefined && (obj.bankName = message.bankName);
    message.deprecatedWallet !== undefined &&
      (obj.deprecatedWallet = message.deprecatedWallet);
    message.providerName !== undefined &&
      (obj.providerName = message.providerName);
    message.active !== undefined && (obj.active = message.active);
    message.reusable !== undefined && (obj.reusable = message.reusable);
    message.flags !== undefined &&
      (obj.flags = message.flags
        ? PaymentMethodCardFlags.toJSON(message.flags)
        : undefined);
    message.scheme !== undefined &&
      (obj.scheme = cardSchemeToJSON(message.scheme));
    message.wallet !== undefined &&
      (obj.wallet = cardWalletToJSON(message.wallet));
    message.eta !== undefined &&
      (obj.eta = message.eta ? ETA.toJSON(message.eta) : undefined);
    return obj;
  },

  create(base?: DeepPartial<PaymentMethodCard>): PaymentMethodCard {
    return PaymentMethodCard.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<PaymentMethodCard>): PaymentMethodCard {
    const message = createBasePaymentMethodCard();
    message.id = object.id ?? '';
    message.maskedPan = object.maskedPan ?? '';
    message.expiry = object.expiry ?? '';
    message.nameOnCard = object.nameOnCard ?? '';
    message.deprecatedScheme = object.deprecatedScheme ?? '';
    message.fee =
      object.fee !== undefined && object.fee !== null
        ? Money.fromPartial(object.fee)
        : undefined;
    message.lastUsedAt =
      object.lastUsedAt !== undefined && object.lastUsedAt !== null
        ? Timestamp.fromPartial(object.lastUsedAt)
        : undefined;
    message.bankName = object.bankName ?? '';
    message.deprecatedWallet = object.deprecatedWallet ?? '';
    message.providerName = object.providerName ?? '';
    message.active = object.active ?? false;
    message.reusable = object.reusable ?? false;
    message.flags =
      object.flags !== undefined && object.flags !== null
        ? PaymentMethodCardFlags.fromPartial(object.flags)
        : undefined;
    message.scheme = object.scheme ?? 0;
    message.wallet = object.wallet ?? 0;
    message.eta =
      object.eta !== undefined && object.eta !== null
        ? ETA.fromPartial(object.eta)
        : undefined;
    return message;
  },
};

function createBasePaymentMethodBankTransfer(): PaymentMethodBankTransfer {
  return {
    id: '',
    reference: '',
    accountDetails: {},
    bankName: '',
    bankAddress: undefined,
    fee: undefined,
    lastUsedAt: undefined,
    accountId: '',
    eta: undefined,
    providerName: '',
  };
}

export const PaymentMethodBankTransfer = {
  encode(
    message: PaymentMethodBankTransfer,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.reference !== '') {
      writer.uint32(18).string(message.reference);
    }
    Object.entries(message.accountDetails).forEach(([key, value]) => {
      PaymentMethodBankTransfer_AccountDetailsEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork(),
      ).ldelim();
    });
    if (message.bankName !== '') {
      writer.uint32(34).string(message.bankName);
    }
    if (message.bankAddress !== undefined) {
      Address.encode(message.bankAddress, writer.uint32(42).fork()).ldelim();
    }
    if (message.fee !== undefined) {
      Money.encode(message.fee, writer.uint32(50).fork()).ldelim();
    }
    if (message.lastUsedAt !== undefined) {
      Timestamp.encode(message.lastUsedAt, writer.uint32(58).fork()).ldelim();
    }
    if (message.accountId !== '') {
      writer.uint32(66).string(message.accountId);
    }
    if (message.eta !== undefined) {
      ETA.encode(message.eta, writer.uint32(74).fork()).ldelim();
    }
    if (message.providerName !== '') {
      writer.uint32(82).string(message.providerName);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PaymentMethodBankTransfer {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentMethodBankTransfer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.reference = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = PaymentMethodBankTransfer_AccountDetailsEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry3.value !== undefined) {
            message.accountDetails[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.bankName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.bankAddress = Address.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fee = Money.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.lastUsedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.eta = ETA.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.providerName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentMethodBankTransfer {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      reference: isSet(object.reference) ? String(object.reference) : '',
      accountDetails: isObject(object.accountDetails)
        ? Object.entries(object.accountDetails).reduce<{
            [key: string]: string;
          }>((acc, [key, value]) => {
            acc[key] = String(value);
            return acc;
          }, {})
        : {},
      bankName: isSet(object.bankName) ? String(object.bankName) : '',
      bankAddress: isSet(object.bankAddress)
        ? Address.fromJSON(object.bankAddress)
        : undefined,
      fee: isSet(object.fee) ? Money.fromJSON(object.fee) : undefined,
      lastUsedAt: isSet(object.lastUsedAt)
        ? fromJsonTimestamp(object.lastUsedAt)
        : undefined,
      accountId: isSet(object.accountId) ? String(object.accountId) : '',
      eta: isSet(object.eta) ? ETA.fromJSON(object.eta) : undefined,
      providerName: isSet(object.providerName)
        ? String(object.providerName)
        : '',
    };
  },

  toJSON(message: PaymentMethodBankTransfer): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.reference !== undefined && (obj.reference = message.reference);
    obj.accountDetails = {};
    if (message.accountDetails) {
      Object.entries(message.accountDetails).forEach(([k, v]) => {
        obj.accountDetails[k] = v;
      });
    }
    message.bankName !== undefined && (obj.bankName = message.bankName);
    message.bankAddress !== undefined &&
      (obj.bankAddress = message.bankAddress
        ? Address.toJSON(message.bankAddress)
        : undefined);
    message.fee !== undefined &&
      (obj.fee = message.fee ? Money.toJSON(message.fee) : undefined);
    message.lastUsedAt !== undefined &&
      (obj.lastUsedAt = fromTimestamp(message.lastUsedAt).toISOString());
    message.accountId !== undefined && (obj.accountId = message.accountId);
    message.eta !== undefined &&
      (obj.eta = message.eta ? ETA.toJSON(message.eta) : undefined);
    message.providerName !== undefined &&
      (obj.providerName = message.providerName);
    return obj;
  },

  create(
    base?: DeepPartial<PaymentMethodBankTransfer>,
  ): PaymentMethodBankTransfer {
    return PaymentMethodBankTransfer.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<PaymentMethodBankTransfer>,
  ): PaymentMethodBankTransfer {
    const message = createBasePaymentMethodBankTransfer();
    message.id = object.id ?? '';
    message.reference = object.reference ?? '';
    message.accountDetails = Object.entries(
      object.accountDetails ?? {},
    ).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.bankName = object.bankName ?? '';
    message.bankAddress =
      object.bankAddress !== undefined && object.bankAddress !== null
        ? Address.fromPartial(object.bankAddress)
        : undefined;
    message.fee =
      object.fee !== undefined && object.fee !== null
        ? Money.fromPartial(object.fee)
        : undefined;
    message.lastUsedAt =
      object.lastUsedAt !== undefined && object.lastUsedAt !== null
        ? Timestamp.fromPartial(object.lastUsedAt)
        : undefined;
    message.accountId = object.accountId ?? '';
    message.eta =
      object.eta !== undefined && object.eta !== null
        ? ETA.fromPartial(object.eta)
        : undefined;
    message.providerName = object.providerName ?? '';
    return message;
  },
};

function createBasePaymentMethodBankTransfer_AccountDetailsEntry(): PaymentMethodBankTransfer_AccountDetailsEntry {
  return { key: '', value: '' };
}

export const PaymentMethodBankTransfer_AccountDetailsEntry = {
  encode(
    message: PaymentMethodBankTransfer_AccountDetailsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PaymentMethodBankTransfer_AccountDetailsEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentMethodBankTransfer_AccountDetailsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentMethodBankTransfer_AccountDetailsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: PaymentMethodBankTransfer_AccountDetailsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(
    base?: DeepPartial<PaymentMethodBankTransfer_AccountDetailsEntry>,
  ): PaymentMethodBankTransfer_AccountDetailsEntry {
    return PaymentMethodBankTransfer_AccountDetailsEntry.fromPartial(
      base ?? {},
    );
  },

  fromPartial(
    object: DeepPartial<PaymentMethodBankTransfer_AccountDetailsEntry>,
  ): PaymentMethodBankTransfer_AccountDetailsEntry {
    const message = createBasePaymentMethodBankTransfer_AccountDetailsEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBasePaymentMethodBankAccount(): PaymentMethodBankAccount {
  return {
    id: '',
    name: undefined,
    type: '',
    data: {},
    whiteListed: false,
    lastUsedAt: undefined,
    providerName: '',
    invalidDetails: false,
    eta: undefined,
  };
}

export const PaymentMethodBankAccount = {
  encode(
    message: PaymentMethodBankAccount,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      Name.encode(message.name, writer.uint32(18).fork()).ldelim();
    }
    if (message.type !== '') {
      writer.uint32(26).string(message.type);
    }
    Object.entries(message.data).forEach(([key, value]) => {
      PaymentMethodBankAccount_DataEntry.encode(
        { key: key as any, value },
        writer.uint32(34).fork(),
      ).ldelim();
    });
    if (message.whiteListed === true) {
      writer.uint32(40).bool(message.whiteListed);
    }
    if (message.lastUsedAt !== undefined) {
      Timestamp.encode(message.lastUsedAt, writer.uint32(50).fork()).ldelim();
    }
    if (message.providerName !== '') {
      writer.uint32(58).string(message.providerName);
    }
    if (message.invalidDetails === true) {
      writer.uint32(64).bool(message.invalidDetails);
    }
    if (message.eta !== undefined) {
      ETA.encode(message.eta, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PaymentMethodBankAccount {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentMethodBankAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = Name.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = PaymentMethodBankAccount_DataEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry4.value !== undefined) {
            message.data[entry4.key] = entry4.value;
          }
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.whiteListed = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.lastUsedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.providerName = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.invalidDetails = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.eta = ETA.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentMethodBankAccount {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name) ? Name.fromJSON(object.name) : undefined,
      type: isSet(object.type) ? String(object.type) : '',
      data: isObject(object.data)
        ? Object.entries(object.data).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
      whiteListed: isSet(object.whiteListed)
        ? Boolean(object.whiteListed)
        : false,
      lastUsedAt: isSet(object.lastUsedAt)
        ? fromJsonTimestamp(object.lastUsedAt)
        : undefined,
      providerName: isSet(object.providerName)
        ? String(object.providerName)
        : '',
      invalidDetails: isSet(object.invalidDetails)
        ? Boolean(object.invalidDetails)
        : false,
      eta: isSet(object.eta) ? ETA.fromJSON(object.eta) : undefined,
    };
  },

  toJSON(message: PaymentMethodBankAccount): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined &&
      (obj.name = message.name ? Name.toJSON(message.name) : undefined);
    message.type !== undefined && (obj.type = message.type);
    obj.data = {};
    if (message.data) {
      Object.entries(message.data).forEach(([k, v]) => {
        obj.data[k] = v;
      });
    }
    message.whiteListed !== undefined &&
      (obj.whiteListed = message.whiteListed);
    message.lastUsedAt !== undefined &&
      (obj.lastUsedAt = fromTimestamp(message.lastUsedAt).toISOString());
    message.providerName !== undefined &&
      (obj.providerName = message.providerName);
    message.invalidDetails !== undefined &&
      (obj.invalidDetails = message.invalidDetails);
    message.eta !== undefined &&
      (obj.eta = message.eta ? ETA.toJSON(message.eta) : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<PaymentMethodBankAccount>,
  ): PaymentMethodBankAccount {
    return PaymentMethodBankAccount.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<PaymentMethodBankAccount>,
  ): PaymentMethodBankAccount {
    const message = createBasePaymentMethodBankAccount();
    message.id = object.id ?? '';
    message.name =
      object.name !== undefined && object.name !== null
        ? Name.fromPartial(object.name)
        : undefined;
    message.type = object.type ?? '';
    message.data = Object.entries(object.data ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.whiteListed = object.whiteListed ?? false;
    message.lastUsedAt =
      object.lastUsedAt !== undefined && object.lastUsedAt !== null
        ? Timestamp.fromPartial(object.lastUsedAt)
        : undefined;
    message.providerName = object.providerName ?? '';
    message.invalidDetails = object.invalidDetails ?? false;
    message.eta =
      object.eta !== undefined && object.eta !== null
        ? ETA.fromPartial(object.eta)
        : undefined;
    return message;
  },
};

function createBasePaymentMethodBankAccount_DataEntry(): PaymentMethodBankAccount_DataEntry {
  return { key: '', value: '' };
}

export const PaymentMethodBankAccount_DataEntry = {
  encode(
    message: PaymentMethodBankAccount_DataEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PaymentMethodBankAccount_DataEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentMethodBankAccount_DataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentMethodBankAccount_DataEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: PaymentMethodBankAccount_DataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(
    base?: DeepPartial<PaymentMethodBankAccount_DataEntry>,
  ): PaymentMethodBankAccount_DataEntry {
    return PaymentMethodBankAccount_DataEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<PaymentMethodBankAccount_DataEntry>,
  ): PaymentMethodBankAccount_DataEntry {
    const message = createBasePaymentMethodBankAccount_DataEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseAdditionalPaymentMethod(): AdditionalPaymentMethod {
  return {
    type: 0,
    providerName: '',
    providerData: {},
    needsToken: false,
    eta: undefined,
  };
}

export const AdditionalPaymentMethod = {
  encode(
    message: AdditionalPaymentMethod,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.providerName !== '') {
      writer.uint32(18).string(message.providerName);
    }
    Object.entries(message.providerData).forEach(([key, value]) => {
      AdditionalPaymentMethod_ProviderDataEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork(),
      ).ldelim();
    });
    if (message.needsToken === true) {
      writer.uint32(32).bool(message.needsToken);
    }
    if (message.eta !== undefined) {
      ETA.encode(message.eta, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AdditionalPaymentMethod {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdditionalPaymentMethod();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.providerName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = AdditionalPaymentMethod_ProviderDataEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry3.value !== undefined) {
            message.providerData[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.needsToken = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.eta = ETA.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdditionalPaymentMethod {
    return {
      type: isSet(object.type) ? paymentMethodTypeFromJSON(object.type) : 0,
      providerName: isSet(object.providerName)
        ? String(object.providerName)
        : '',
      providerData: isObject(object.providerData)
        ? Object.entries(object.providerData).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
      needsToken: isSet(object.needsToken) ? Boolean(object.needsToken) : false,
      eta: isSet(object.eta) ? ETA.fromJSON(object.eta) : undefined,
    };
  },

  toJSON(message: AdditionalPaymentMethod): unknown {
    const obj: any = {};
    message.type !== undefined &&
      (obj.type = paymentMethodTypeToJSON(message.type));
    message.providerName !== undefined &&
      (obj.providerName = message.providerName);
    obj.providerData = {};
    if (message.providerData) {
      Object.entries(message.providerData).forEach(([k, v]) => {
        obj.providerData[k] = v;
      });
    }
    message.needsToken !== undefined && (obj.needsToken = message.needsToken);
    message.eta !== undefined &&
      (obj.eta = message.eta ? ETA.toJSON(message.eta) : undefined);
    return obj;
  },

  create(base?: DeepPartial<AdditionalPaymentMethod>): AdditionalPaymentMethod {
    return AdditionalPaymentMethod.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<AdditionalPaymentMethod>,
  ): AdditionalPaymentMethod {
    const message = createBaseAdditionalPaymentMethod();
    message.type = object.type ?? 0;
    message.providerName = object.providerName ?? '';
    message.providerData = Object.entries(object.providerData ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.needsToken = object.needsToken ?? false;
    message.eta =
      object.eta !== undefined && object.eta !== null
        ? ETA.fromPartial(object.eta)
        : undefined;
    return message;
  },
};

function createBaseAdditionalPaymentMethod_ProviderDataEntry(): AdditionalPaymentMethod_ProviderDataEntry {
  return { key: '', value: '' };
}

export const AdditionalPaymentMethod_ProviderDataEntry = {
  encode(
    message: AdditionalPaymentMethod_ProviderDataEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AdditionalPaymentMethod_ProviderDataEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdditionalPaymentMethod_ProviderDataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdditionalPaymentMethod_ProviderDataEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: AdditionalPaymentMethod_ProviderDataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(
    base?: DeepPartial<AdditionalPaymentMethod_ProviderDataEntry>,
  ): AdditionalPaymentMethod_ProviderDataEntry {
    return AdditionalPaymentMethod_ProviderDataEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<AdditionalPaymentMethod_ProviderDataEntry>,
  ): AdditionalPaymentMethod_ProviderDataEntry {
    const message = createBaseAdditionalPaymentMethod_ProviderDataEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseLastUsedPaymentMethod(): LastUsedPaymentMethod {
  return { type: 0, providerName: '', methodId: '' };
}

export const LastUsedPaymentMethod = {
  encode(
    message: LastUsedPaymentMethod,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.providerName !== '') {
      writer.uint32(18).string(message.providerName);
    }
    if (message.methodId !== '') {
      writer.uint32(26).string(message.methodId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): LastUsedPaymentMethod {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLastUsedPaymentMethod();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.providerName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.methodId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LastUsedPaymentMethod {
    return {
      type: isSet(object.type) ? paymentMethodTypeFromJSON(object.type) : 0,
      providerName: isSet(object.providerName)
        ? String(object.providerName)
        : '',
      methodId: isSet(object.methodId) ? String(object.methodId) : '',
    };
  },

  toJSON(message: LastUsedPaymentMethod): unknown {
    const obj: any = {};
    message.type !== undefined &&
      (obj.type = paymentMethodTypeToJSON(message.type));
    message.providerName !== undefined &&
      (obj.providerName = message.providerName);
    message.methodId !== undefined && (obj.methodId = message.methodId);
    return obj;
  },

  create(base?: DeepPartial<LastUsedPaymentMethod>): LastUsedPaymentMethod {
    return LastUsedPaymentMethod.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<LastUsedPaymentMethod>,
  ): LastUsedPaymentMethod {
    const message = createBaseLastUsedPaymentMethod();
    message.type = object.type ?? 0;
    message.providerName = object.providerName ?? '';
    message.methodId = object.methodId ?? '';
    return message;
  },
};

function createBasePaymentMethodWallet(): PaymentMethodWallet {
  return {
    id: '',
    name: '',
    balance: undefined,
    icon: 0,
    eta: undefined,
    details: undefined,
  };
}

export const PaymentMethodWallet = {
  encode(
    message: PaymentMethodWallet,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    if (message.balance !== undefined) {
      Money.encode(message.balance, writer.uint32(26).fork()).ldelim();
    }
    if (message.icon !== 0) {
      writer.uint32(32).int32(message.icon);
    }
    if (message.eta !== undefined) {
      ETA.encode(message.eta, writer.uint32(42).fork()).ldelim();
    }
    if (message.details !== undefined) {
      Wallet.encode(message.details, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaymentMethodWallet {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentMethodWallet();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.balance = Money.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.icon = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.eta = ETA.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.details = Wallet.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentMethodWallet {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name) ? String(object.name) : '',
      balance: isSet(object.balance)
        ? Money.fromJSON(object.balance)
        : undefined,
      icon: isSet(object.icon) ? walletIconFromJSON(object.icon) : 0,
      eta: isSet(object.eta) ? ETA.fromJSON(object.eta) : undefined,
      details: isSet(object.details)
        ? Wallet.fromJSON(object.details)
        : undefined,
    };
  },

  toJSON(message: PaymentMethodWallet): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.balance !== undefined &&
      (obj.balance = message.balance
        ? Money.toJSON(message.balance)
        : undefined);
    message.icon !== undefined && (obj.icon = walletIconToJSON(message.icon));
    message.eta !== undefined &&
      (obj.eta = message.eta ? ETA.toJSON(message.eta) : undefined);
    message.details !== undefined &&
      (obj.details = message.details
        ? Wallet.toJSON(message.details)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<PaymentMethodWallet>): PaymentMethodWallet {
    return PaymentMethodWallet.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<PaymentMethodWallet>): PaymentMethodWallet {
    const message = createBasePaymentMethodWallet();
    message.id = object.id ?? '';
    message.name = object.name ?? '';
    message.balance =
      object.balance !== undefined && object.balance !== null
        ? Money.fromPartial(object.balance)
        : undefined;
    message.icon = object.icon ?? 0;
    message.eta =
      object.eta !== undefined && object.eta !== null
        ? ETA.fromPartial(object.eta)
        : undefined;
    message.details =
      object.details !== undefined && object.details !== null
        ? Wallet.fromPartial(object.details)
        : undefined;
    return message;
  },
};

function createBaseCardData(): CardData {
  return { number: '', expiry: '', cvv: '' };
}

export const CardData = {
  encode(
    message: CardData,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.number !== '') {
      writer.uint32(10).string(message.number);
    }
    if (message.expiry !== '') {
      writer.uint32(18).string(message.expiry);
    }
    if (message.cvv !== '') {
      writer.uint32(26).string(message.cvv);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CardData {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCardData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.number = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.expiry = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.cvv = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CardData {
    return {
      number: isSet(object.number) ? String(object.number) : '',
      expiry: isSet(object.expiry) ? String(object.expiry) : '',
      cvv: isSet(object.cvv) ? String(object.cvv) : '',
    };
  },

  toJSON(message: CardData): unknown {
    const obj: any = {};
    message.number !== undefined && (obj.number = message.number);
    message.expiry !== undefined && (obj.expiry = message.expiry);
    message.cvv !== undefined && (obj.cvv = message.cvv);
    return obj;
  },

  create(base?: DeepPartial<CardData>): CardData {
    return CardData.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<CardData>): CardData {
    const message = createBaseCardData();
    message.number = object.number ?? '';
    message.expiry = object.expiry ?? '';
    message.cvv = object.cvv ?? '';
    return message;
  },
};

function createBasePaymentSummary(): PaymentSummary {
  return { items: [], total: undefined };
}

export const PaymentSummary = {
  encode(
    message: PaymentSummary,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.items) {
      PaymentSummaryItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.total !== undefined) {
      PaymentSummaryItem.encode(
        message.total,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaymentSummary {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.items.push(
            PaymentSummaryItem.decode(reader, reader.uint32()),
          );
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.total = PaymentSummaryItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentSummary {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => PaymentSummaryItem.fromJSON(e))
        : [],
      total: isSet(object.total)
        ? PaymentSummaryItem.fromJSON(object.total)
        : undefined,
    };
  },

  toJSON(message: PaymentSummary): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) =>
        e ? PaymentSummaryItem.toJSON(e) : undefined,
      );
    } else {
      obj.items = [];
    }
    message.total !== undefined &&
      (obj.total = message.total
        ? PaymentSummaryItem.toJSON(message.total)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<PaymentSummary>): PaymentSummary {
    return PaymentSummary.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<PaymentSummary>): PaymentSummary {
    const message = createBasePaymentSummary();
    message.items =
      object.items?.map((e) => PaymentSummaryItem.fromPartial(e)) || [];
    message.total =
      object.total !== undefined && object.total !== null
        ? PaymentSummaryItem.fromPartial(object.total)
        : undefined;
    return message;
  },
};

function createBasePaymentSummaryItem(): PaymentSummaryItem {
  return { label: '', amount: undefined };
}

export const PaymentSummaryItem = {
  encode(
    message: PaymentSummaryItem,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.label !== '') {
      writer.uint32(10).string(message.label);
    }
    if (message.amount !== undefined) {
      Money.encode(message.amount, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaymentSummaryItem {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentSummaryItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.label = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.amount = Money.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentSummaryItem {
    return {
      label: isSet(object.label) ? String(object.label) : '',
      amount: isSet(object.amount) ? Money.fromJSON(object.amount) : undefined,
    };
  },

  toJSON(message: PaymentSummaryItem): unknown {
    const obj: any = {};
    message.label !== undefined && (obj.label = message.label);
    message.amount !== undefined &&
      (obj.amount = message.amount ? Money.toJSON(message.amount) : undefined);
    return obj;
  },

  create(base?: DeepPartial<PaymentSummaryItem>): PaymentSummaryItem {
    return PaymentSummaryItem.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<PaymentSummaryItem>): PaymentSummaryItem {
    const message = createBasePaymentSummaryItem();
    message.label = object.label ?? '';
    message.amount =
      object.amount !== undefined && object.amount !== null
        ? Money.fromPartial(object.amount)
        : undefined;
    return message;
  },
};

function createBasePaymentCapabilities(): PaymentCapabilities {
  return { merchantCapabilities: [], cardSchemes: [] };
}

export const PaymentCapabilities = {
  encode(
    message: PaymentCapabilities,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    writer.uint32(26).fork();
    for (const v of message.merchantCapabilities) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(34).fork();
    for (const v of message.cardSchemes) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaymentCapabilities {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentCapabilities();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag === 24) {
            message.merchantCapabilities.push(reader.int32() as any);

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.merchantCapabilities.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 4:
          if (tag === 32) {
            message.cardSchemes.push(reader.int32() as any);

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.cardSchemes.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentCapabilities {
    return {
      merchantCapabilities: Array.isArray(object?.merchantCapabilities)
        ? object.merchantCapabilities.map((e: any) =>
            merchantCapabilityFromJSON(e),
          )
        : [],
      cardSchemes: Array.isArray(object?.cardSchemes)
        ? object.cardSchemes.map((e: any) => cardSchemeFromJSON(e))
        : [],
    };
  },

  toJSON(message: PaymentCapabilities): unknown {
    const obj: any = {};
    if (message.merchantCapabilities) {
      obj.merchantCapabilities = message.merchantCapabilities.map((e) =>
        merchantCapabilityToJSON(e),
      );
    } else {
      obj.merchantCapabilities = [];
    }
    if (message.cardSchemes) {
      obj.cardSchemes = message.cardSchemes.map((e) => cardSchemeToJSON(e));
    } else {
      obj.cardSchemes = [];
    }
    return obj;
  },

  create(base?: DeepPartial<PaymentCapabilities>): PaymentCapabilities {
    return PaymentCapabilities.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<PaymentCapabilities>): PaymentCapabilities {
    const message = createBasePaymentCapabilities();
    message.merchantCapabilities =
      object.merchantCapabilities?.map((e) => e) || [];
    message.cardSchemes = object.cardSchemes?.map((e) => e) || [];
    return message;
  },
};

function createBasePaymentMethodOffPlatform(): PaymentMethodOffPlatform {
  return { name: '', currency: '' };
}

export const PaymentMethodOffPlatform = {
  encode(
    message: PaymentMethodOffPlatform,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.name !== '') {
      writer.uint32(10).string(message.name);
    }
    if (message.currency !== '') {
      writer.uint32(18).string(message.currency);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PaymentMethodOffPlatform {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentMethodOffPlatform();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.currency = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentMethodOffPlatform {
    return {
      name: isSet(object.name) ? String(object.name) : '',
      currency: isSet(object.currency) ? String(object.currency) : '',
    };
  },

  toJSON(message: PaymentMethodOffPlatform): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.currency !== undefined && (obj.currency = message.currency);
    return obj;
  },

  create(
    base?: DeepPartial<PaymentMethodOffPlatform>,
  ): PaymentMethodOffPlatform {
    return PaymentMethodOffPlatform.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<PaymentMethodOffPlatform>,
  ): PaymentMethodOffPlatform {
    const message = createBasePaymentMethodOffPlatform();
    message.name = object.name ?? '';
    message.currency = object.currency ?? '';
    return message;
  },
};

function createBaseRequirementAmount(): RequirementAmount {
  return { min: undefined, max: undefined };
}

export const RequirementAmount = {
  encode(
    message: RequirementAmount,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.min !== undefined) {
      Money.encode(message.min, writer.uint32(10).fork()).ldelim();
    }
    if (message.max !== undefined) {
      Money.encode(message.max, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RequirementAmount {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequirementAmount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.min = Money.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.max = Money.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RequirementAmount {
    return {
      min: isSet(object.min) ? Money.fromJSON(object.min) : undefined,
      max: isSet(object.max) ? Money.fromJSON(object.max) : undefined,
    };
  },

  toJSON(message: RequirementAmount): unknown {
    const obj: any = {};
    message.min !== undefined &&
      (obj.min = message.min ? Money.toJSON(message.min) : undefined);
    message.max !== undefined &&
      (obj.max = message.max ? Money.toJSON(message.max) : undefined);
    return obj;
  },

  create(base?: DeepPartial<RequirementAmount>): RequirementAmount {
    return RequirementAmount.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<RequirementAmount>): RequirementAmount {
    const message = createBaseRequirementAmount();
    message.min =
      object.min !== undefined && object.min !== null
        ? Money.fromPartial(object.min)
        : undefined;
    message.max =
      object.max !== undefined && object.max !== null
        ? Money.fromPartial(object.max)
        : undefined;
    return message;
  },
};

function createBaseRequirementCurrentTime(): RequirementCurrentTime {
  return { min: undefined, max: undefined };
}

export const RequirementCurrentTime = {
  encode(
    message: RequirementCurrentTime,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.min !== undefined) {
      Timestamp.encode(message.min, writer.uint32(10).fork()).ldelim();
    }
    if (message.max !== undefined) {
      Timestamp.encode(message.max, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RequirementCurrentTime {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequirementCurrentTime();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.min = Timestamp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.max = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RequirementCurrentTime {
    return {
      min: isSet(object.min) ? fromJsonTimestamp(object.min) : undefined,
      max: isSet(object.max) ? fromJsonTimestamp(object.max) : undefined,
    };
  },

  toJSON(message: RequirementCurrentTime): unknown {
    const obj: any = {};
    message.min !== undefined &&
      (obj.min = fromTimestamp(message.min).toISOString());
    message.max !== undefined &&
      (obj.max = fromTimestamp(message.max).toISOString());
    return obj;
  },

  create(base?: DeepPartial<RequirementCurrentTime>): RequirementCurrentTime {
    return RequirementCurrentTime.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<RequirementCurrentTime>,
  ): RequirementCurrentTime {
    const message = createBaseRequirementCurrentTime();
    message.min =
      object.min !== undefined && object.min !== null
        ? Timestamp.fromPartial(object.min)
        : undefined;
    message.max =
      object.max !== undefined && object.max !== null
        ? Timestamp.fromPartial(object.max)
        : undefined;
    return message;
  },
};

function createBaseRequirement(): Requirement {
  return {
    type: 0,
    description: '',
    amount: undefined,
    currentTime: undefined,
  };
}

export const Requirement = {
  encode(
    message: Requirement,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.description !== '') {
      writer.uint32(18).string(message.description);
    }
    if (message.amount !== undefined) {
      RequirementAmount.encode(
        message.amount,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.currentTime !== undefined) {
      RequirementCurrentTime.encode(
        message.currentTime,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Requirement {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequirement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.amount = RequirementAmount.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.currentTime = RequirementCurrentTime.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Requirement {
    return {
      type: isSet(object.type) ? requirementTypeFromJSON(object.type) : 0,
      description: isSet(object.description) ? String(object.description) : '',
      amount: isSet(object.amount)
        ? RequirementAmount.fromJSON(object.amount)
        : undefined,
      currentTime: isSet(object.currentTime)
        ? RequirementCurrentTime.fromJSON(object.currentTime)
        : undefined,
    };
  },

  toJSON(message: Requirement): unknown {
    const obj: any = {};
    message.type !== undefined &&
      (obj.type = requirementTypeToJSON(message.type));
    message.description !== undefined &&
      (obj.description = message.description);
    message.amount !== undefined &&
      (obj.amount = message.amount
        ? RequirementAmount.toJSON(message.amount)
        : undefined);
    message.currentTime !== undefined &&
      (obj.currentTime = message.currentTime
        ? RequirementCurrentTime.toJSON(message.currentTime)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<Requirement>): Requirement {
    return Requirement.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Requirement>): Requirement {
    const message = createBaseRequirement();
    message.type = object.type ?? 0;
    message.description = object.description ?? '';
    message.amount =
      object.amount !== undefined && object.amount !== null
        ? RequirementAmount.fromPartial(object.amount)
        : undefined;
    message.currentTime =
      object.currentTime !== undefined && object.currentTime !== null
        ? RequirementCurrentTime.fromPartial(object.currentTime)
        : undefined;
    return message;
  },
};

function createBasePaymentSource(): PaymentSource {
  return {
    type: 0,
    wallet: undefined,
    offPlatform: undefined,
    requirements: [],
  };
}

export const PaymentSource = {
  encode(
    message: PaymentSource,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.wallet !== undefined) {
      PaymentMethodWallet.encode(
        message.wallet,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.offPlatform !== undefined) {
      PaymentMethodOffPlatform.encode(
        message.offPlatform,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    for (const v of message.requirements) {
      Requirement.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaymentSource {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentSource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.wallet = PaymentMethodWallet.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.offPlatform = PaymentMethodOffPlatform.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.requirements.push(
            Requirement.decode(reader, reader.uint32()),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PaymentSource {
    return {
      type: isSet(object.type) ? paymentMethodTypeFromJSON(object.type) : 0,
      wallet: isSet(object.wallet)
        ? PaymentMethodWallet.fromJSON(object.wallet)
        : undefined,
      offPlatform: isSet(object.offPlatform)
        ? PaymentMethodOffPlatform.fromJSON(object.offPlatform)
        : undefined,
      requirements: Array.isArray(object?.requirements)
        ? object.requirements.map((e: any) => Requirement.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PaymentSource): unknown {
    const obj: any = {};
    message.type !== undefined &&
      (obj.type = paymentMethodTypeToJSON(message.type));
    message.wallet !== undefined &&
      (obj.wallet = message.wallet
        ? PaymentMethodWallet.toJSON(message.wallet)
        : undefined);
    message.offPlatform !== undefined &&
      (obj.offPlatform = message.offPlatform
        ? PaymentMethodOffPlatform.toJSON(message.offPlatform)
        : undefined);
    if (message.requirements) {
      obj.requirements = message.requirements.map((e) =>
        e ? Requirement.toJSON(e) : undefined,
      );
    } else {
      obj.requirements = [];
    }
    return obj;
  },

  create(base?: DeepPartial<PaymentSource>): PaymentSource {
    return PaymentSource.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<PaymentSource>): PaymentSource {
    const message = createBasePaymentSource();
    message.type = object.type ?? 0;
    message.wallet =
      object.wallet !== undefined && object.wallet !== null
        ? PaymentMethodWallet.fromPartial(object.wallet)
        : undefined;
    message.offPlatform =
      object.offPlatform !== undefined && object.offPlatform !== null
        ? PaymentMethodOffPlatform.fromPartial(object.offPlatform)
        : undefined;
    message.requirements =
      object.requirements?.map((e) => Requirement.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFundingOption(): FundingOption {
  return { displayName: '', fundingAccountId: '', currency: '', country: '' };
}

export const FundingOption = {
  encode(
    message: FundingOption,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.displayName !== '') {
      writer.uint32(10).string(message.displayName);
    }
    if (message.fundingAccountId !== '') {
      writer.uint32(18).string(message.fundingAccountId);
    }
    if (message.currency !== '') {
      writer.uint32(26).string(message.currency);
    }
    if (message.country !== '') {
      writer.uint32(34).string(message.country);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FundingOption {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFundingOption();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fundingAccountId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.country = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FundingOption {
    return {
      displayName: isSet(object.displayName) ? String(object.displayName) : '',
      fundingAccountId: isSet(object.fundingAccountId)
        ? String(object.fundingAccountId)
        : '',
      currency: isSet(object.currency) ? String(object.currency) : '',
      country: isSet(object.country) ? String(object.country) : '',
    };
  },

  toJSON(message: FundingOption): unknown {
    const obj: any = {};
    message.displayName !== undefined &&
      (obj.displayName = message.displayName);
    message.fundingAccountId !== undefined &&
      (obj.fundingAccountId = message.fundingAccountId);
    message.currency !== undefined && (obj.currency = message.currency);
    message.country !== undefined && (obj.country = message.country);
    return obj;
  },

  create(base?: DeepPartial<FundingOption>): FundingOption {
    return FundingOption.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<FundingOption>): FundingOption {
    const message = createBaseFundingOption();
    message.displayName = object.displayName ?? '';
    message.fundingAccountId = object.fundingAccountId ?? '';
    message.currency = object.currency ?? '';
    message.country = object.country ?? '';
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Timestamp {
  if (o instanceof Date) {
    return toTimestamp(o);
  } else if (typeof o === 'string') {
    return toTimestamp(new Date(o));
  } else {
    return Timestamp.fromJSON(o);
  }
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
