/* eslint-disable */
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'utils';

/** IconSourceType specifies the type of icon source, e.g. a URL or an icon pack like Font Awesome. */
export enum IconSourceType {
  ICON_SOURCE_TYPE_INVALID = 0,
  ICON_SOURCE_TYPE_URL = 1,
  /**
   * ICON_SOURCE_TYPE_FONT_AWESOME - Font Awesome icon pack.
   * Source ref is the icon name without "fa-" prefix, e.g. "heart".
   * Icons must be free and in version 6.2+, see https://fontawesome.com/v6/search?o=r&m=free.
   * Variant must be specified.
   */
  ICON_SOURCE_TYPE_FONT_AWESOME = 2,
  /** ICON_SOURCE_TYPE_ASSET - Asset is a client-side local asset. */
  ICON_SOURCE_TYPE_ASSET = 3,
  UNRECOGNIZED = -1,
}

export function iconSourceTypeFromJSON(object: any): IconSourceType {
  switch (object) {
    case 0:
    case 'ICON_SOURCE_TYPE_INVALID':
      return IconSourceType.ICON_SOURCE_TYPE_INVALID;
    case 1:
    case 'ICON_SOURCE_TYPE_URL':
      return IconSourceType.ICON_SOURCE_TYPE_URL;
    case 2:
    case 'ICON_SOURCE_TYPE_FONT_AWESOME':
      return IconSourceType.ICON_SOURCE_TYPE_FONT_AWESOME;
    case 3:
    case 'ICON_SOURCE_TYPE_ASSET':
      return IconSourceType.ICON_SOURCE_TYPE_ASSET;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return IconSourceType.UNRECOGNIZED;
  }
}

export function iconSourceTypeToJSON(object: IconSourceType): string {
  switch (object) {
    case IconSourceType.ICON_SOURCE_TYPE_INVALID:
      return 'ICON_SOURCE_TYPE_INVALID';
    case IconSourceType.ICON_SOURCE_TYPE_URL:
      return 'ICON_SOURCE_TYPE_URL';
    case IconSourceType.ICON_SOURCE_TYPE_FONT_AWESOME:
      return 'ICON_SOURCE_TYPE_FONT_AWESOME';
    case IconSourceType.ICON_SOURCE_TYPE_ASSET:
      return 'ICON_SOURCE_TYPE_ASSET';
    case IconSourceType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** IconVariant specifies the variant (or "style" for Font Awesome) of an icon. */
export enum IconVariant {
  ICON_VARIANT_INVALID = 0,
  ICON_VARIANT_REGULAR = 1,
  ICON_VARIANT_SOLID = 2,
  UNRECOGNIZED = -1,
}

export function iconVariantFromJSON(object: any): IconVariant {
  switch (object) {
    case 0:
    case 'ICON_VARIANT_INVALID':
      return IconVariant.ICON_VARIANT_INVALID;
    case 1:
    case 'ICON_VARIANT_REGULAR':
      return IconVariant.ICON_VARIANT_REGULAR;
    case 2:
    case 'ICON_VARIANT_SOLID':
      return IconVariant.ICON_VARIANT_SOLID;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return IconVariant.UNRECOGNIZED;
  }
}

export function iconVariantToJSON(object: IconVariant): string {
  switch (object) {
    case IconVariant.ICON_VARIANT_INVALID:
      return 'ICON_VARIANT_INVALID';
    case IconVariant.ICON_VARIANT_REGULAR:
      return 'ICON_VARIANT_REGULAR';
    case IconVariant.ICON_VARIANT_SOLID:
      return 'ICON_VARIANT_SOLID';
    case IconVariant.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * ColourStyling allows specifying the colours for text or an icon.
 * Colours are specified with hex codes including the leading `#`.
 */
export interface ColourStyling {
  foreground: string;
  background: string;
}

/**
 * Icon references an icon in one of different ways (IconSourceType).
 * Depending on source type, icons can be stacked, listed from background to foreground.
 * Stacking is supported by Font Awesome icons.
 */
export interface Icon {
  sourceType: IconSourceType;
  /**
   * SourceRef is a reference to the icon source.
   * See the specific IconSourceType for details per source type.
   */
  sourceRef: string;
  /** Optional depending on source type. */
  variant: IconVariant;
  /** Optional. */
  colours: ColourStyling | undefined;
  /** Optional. Icon size in pixels. */
  size: number;
}

function createBaseColourStyling(): ColourStyling {
  return { foreground: '', background: '' };
}

export const ColourStyling = {
  encode(
    message: ColourStyling,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.foreground !== '') {
      writer.uint32(10).string(message.foreground);
    }
    if (message.background !== '') {
      writer.uint32(18).string(message.background);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ColourStyling {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseColourStyling();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.foreground = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.background = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ColourStyling {
    return {
      foreground: isSet(object.foreground) ? String(object.foreground) : '',
      background: isSet(object.background) ? String(object.background) : '',
    };
  },

  toJSON(message: ColourStyling): unknown {
    const obj: any = {};
    message.foreground !== undefined && (obj.foreground = message.foreground);
    message.background !== undefined && (obj.background = message.background);
    return obj;
  },

  create(base?: DeepPartial<ColourStyling>): ColourStyling {
    return ColourStyling.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ColourStyling>): ColourStyling {
    const message = createBaseColourStyling();
    message.foreground = object.foreground ?? '';
    message.background = object.background ?? '';
    return message;
  },
};

function createBaseIcon(): Icon {
  return {
    sourceType: 0,
    sourceRef: '',
    variant: 0,
    colours: undefined,
    size: 0,
  };
}

export const Icon = {
  encode(message: Icon, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sourceType !== 0) {
      writer.uint32(8).int32(message.sourceType);
    }
    if (message.sourceRef !== '') {
      writer.uint32(18).string(message.sourceRef);
    }
    if (message.variant !== 0) {
      writer.uint32(24).int32(message.variant);
    }
    if (message.colours !== undefined) {
      ColourStyling.encode(message.colours, writer.uint32(34).fork()).ldelim();
    }
    if (message.size !== 0) {
      writer.uint32(40).int32(message.size);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Icon {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIcon();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.sourceType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sourceRef = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.variant = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.colours = ColourStyling.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.size = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Icon {
    return {
      sourceType: isSet(object.sourceType)
        ? iconSourceTypeFromJSON(object.sourceType)
        : 0,
      sourceRef: isSet(object.sourceRef) ? String(object.sourceRef) : '',
      variant: isSet(object.variant) ? iconVariantFromJSON(object.variant) : 0,
      colours: isSet(object.colours)
        ? ColourStyling.fromJSON(object.colours)
        : undefined,
      size: isSet(object.size) ? Number(object.size) : 0,
    };
  },

  toJSON(message: Icon): unknown {
    const obj: any = {};
    message.sourceType !== undefined &&
      (obj.sourceType = iconSourceTypeToJSON(message.sourceType));
    message.sourceRef !== undefined && (obj.sourceRef = message.sourceRef);
    message.variant !== undefined &&
      (obj.variant = iconVariantToJSON(message.variant));
    message.colours !== undefined &&
      (obj.colours = message.colours
        ? ColourStyling.toJSON(message.colours)
        : undefined);
    message.size !== undefined && (obj.size = Math.round(message.size));
    return obj;
  },

  create(base?: DeepPartial<Icon>): Icon {
    return Icon.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Icon>): Icon {
    const message = createBaseIcon();
    message.sourceType = object.sourceType ?? 0;
    message.sourceRef = object.sourceRef ?? '';
    message.variant = object.variant ?? 0;
    message.colours =
      object.colours !== undefined && object.colours !== null
        ? ColourStyling.fromPartial(object.colours)
        : undefined;
    message.size = object.size ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
