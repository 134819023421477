import React, { useEffect } from 'react';

import api from '../lib/api';
import { bootIntercom } from '../lib/intercom';

const useIntercom = () => {
  const defaultProfile = api.useGetDefaultProfileQuery();

  useEffect(() => {
    bootIntercom();
  }, []);

  useEffect(() => {
    if (defaultProfile.data) {
      window.Intercom('update', {
        user_id: defaultProfile.data.user?.id,
        user_hash:
          defaultProfile.data.user?.clientProperties?.intercomSignedUserIdWeb,
      });
    }
  }, [defaultProfile.data]);
};

type IntercomProviderProps = {
  children: React.ReactNode;
};

const IntercomProvider = ({ children }: IntercomProviderProps) => {
  useIntercom();

  return children as JSX.Element;
};

export default IntercomProvider;
