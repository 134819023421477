/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { PURGE, REHYDRATE, RehydrateAction } from 'redux-persist';

import { Account, User } from '../grpc-web/common/proto/users/types';
import api from '../lib/api';
import grpc from '../lib/grpc';

type SessionState = {
  /**
   * User: the api has provided us a Nala user
   * undefined: the api ha told us there is no user associated with this authentication token
   * null: the api is yet to respond with a user
   */
  user: User | undefined | null;
  selectedAccount: Account | null;
};

const initialState: SessionState = {
  user: null,
  selectedAccount: null,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSelectedAccount: (state, action: PayloadAction<Account | null>) => {
      state.selectedAccount = action.payload;
      if (action.payload?.id) {
        grpc.metadata.set('account_id', action.payload?.id);
      }
    },
    clearSelectedAccount: (state) => {
      state.selectedAccount = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
    builder.addCase<typeof REHYDRATE, RehydrateAction>(
      REHYDRATE,
      (_, action) => {
        const safeAccountId = get(
          action,
          'payload.session.selectedAccount.id',
          null,
        ) as string | null;
        if (safeAccountId) {
          grpc.metadata.set('account_id', safeAccountId);
        }
      },
    );
    builder.addMatcher(
      api.endpoints.getDefaultProfile.matchFulfilled,
      (state, action) => {
        state.user = action.payload.user;
      },
    );
    builder.addMatcher(
      api.endpoints.getDefaultProfile.matchFulfilled,
      (state, action) => {
        if (state.selectedAccount === null) {
          state.selectedAccount = action.payload.account ?? null;

          if (action.payload.account?.id) {
            grpc.metadata.set('account_id', action.payload.account.id);
          }
        }
      },
    );
  },
});

export const { setSelectedAccount, clearSelectedAccount } =
  sessionSlice.actions;

export default sessionSlice;
